const initialConfig = (url, data) => ({
    method: 'post',
    url: url,
    headers: {
        'Authorization': 'Bearer null', 
        'Content-Type': 'application/json',
        'accept' : 'application/json'
    },
    data: data
});

export default initialConfig