import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    resendConfirmationData: [],
    resendConfirmationLoading: false,
    resendConfirmationError: null,
};


export const resendConfirmationFunc = createAsyncThunk("resendConfirmation/resendConfirmationFunc", async ({email}) => {

    var config = {
        method: 'get',
        url: `https://api.internif.com/auth/auth/resendEmailConfirmation?&email=${email}`,
        headers: {}
    };

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        console.log(err.response.data);
        return err;
    });

    const data = await response;
    return data;
});


const resendConfirmation = createSlice({
    name: "resendConfirmation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resendConfirmationFunc.pending, (state) => {
            state.resendConfirmationLoading = true;
        });
        builder.addCase(resendConfirmationFunc.fulfilled, (state, action) => {
            state.resendConfirmationLoading = false;
            console.log(action.payload);
            state.resendConfirmationData = action.payload;
        });
        builder.addCase(resendConfirmationFunc.rejected, (state, action) => {
            state.resendConfirmationLoading = false;
            state.resendConfirmationError = action.error.message;
        });
    }
});

export default resendConfirmation.reducer;