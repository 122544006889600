import React from 'react';
import { Box, Link, Typography } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

// import UpperMenuItem from './UpperMenuItem'
import { useDashBoardContext } from 'layouts/DashboardLayout/DashboardContext/DashboardContext';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontFamily: 'Causten-Regular',
        fontSize: '14px',
        lineHeight: '16.8px',
        padding: "8px",
        // margin: '75px',
        position: 'relative',
        left: '20px',
    },
}));


const UpperMenu = () => {

    const { dashboardSideMenuList, currentDashboardPage, dashboardSideBarWidth, setCurrentDashboardPage } = useDashBoardContext()

    return (
        <Box
            sx={{
                mb: { xs: '50px', sm: '70px' },
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
            }}
        >

            {
                dashboardSideMenuList?.upperMenu?.items?.map((menuItem, index) => {

                    const isActive = menuItem?.text === currentDashboardPage?.text
                    // console.log(menuItem?.text, currentDashboardPage?.text)
                    let badge = true

                    return (
                        <Box key={index} onClick={() => setCurrentDashboardPage((menuItem))} >

                            {
                                dashboardSideBarWidth ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: !dashboardSideBarWidth && 'center',
                                            backgroundColor: isActive ? '#FF9737' : 'transparent',
                                            borderRadius: '8px',
                                        }}
                                    >

                                        <Link
                                            to={menuItem?.link ?? ""}
                                            component={RouterLink}
                                            sx={{
                                                width: '100%',
                                                p: { xs: '12px' },
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: !dashboardSideBarWidth && 'center',
                                                textDecoration: 'none',

                                                '&:hover': {
                                                    textDecoration: 'none',
                                                },
                                            }}
                                        >

                                            <Box
                                                sx={{
                                                    color: isActive ? '#fff' : '#70798D',
                                                    width: { xs: '20px' },
                                                    height: { xs: '20px' },
                                                    borderRadius: '50%',
                                                    // mr: { xs: dashboardSideBarWidth ? '12px' : '0' }
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    // color:'#fff'
                                                }}
                                            >
                                                {/* {menuItem?.icon(Mycolor)} */}
                                                {menuItem?.icon(isActive ? '#fff' : '#70798D')}
                                            </Box>

                                            <Typography
                                                sx={{
                                                    fontFamily: 'Causten-Regular',
                                                    fontSize: { xs: '14px' },
                                                    lineHeight: { xs: '16.8px' },
                                                    color: isActive ? '#fff' : '#70798D',
                                                    // width:'100%',
                                                    pl: { xs: '12px' },
                                                    mr: { xs: '12px' },
                                                    flexGrow: 1,
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {menuItem?.text}
                                            </Typography>

                                            {
                                                menuItem?.badge ? (<Badge menuItem={menuItem} isActive={isActive} />) : (<></>)
                                            }

                                        </Link>

                                    </Box>
                                ) : (
                                    <LightTooltip title={menuItem?.text} placement="right" >
                                        <Link
                                            to={menuItem?.link ?? ""}
                                            component={RouterLink}
                                            sx={{
                                                backgroundColor: isActive ? '#FF9737' : 'transparent',
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: "center",
                                                p: { xs: '12px' },
                                                textDecoration: 'none',
                                                borderRadius: '8px',
                                                position: 'relative',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: { xs: '20px' },
                                                    height: { xs: '20px' },
                                                    color: isActive ? '#fff' : '#70798D',
                                                    display: "flex",
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {menuItem?.icon(isActive ? '#fff' : '#70798D')}
                                            </Box>

                                            {
                                                menuItem?.badge ? (<Badge menuItem={menuItem} isActive={isActive} />) : (<></>)
                                            }


                                        </Link>
                                    </LightTooltip>
                                )
                            }

                        </Box>
                    )
                })
            }

        </Box >
    )
}


const Badge = ({ menuItem, isActive }) => {

    const { dashboardSideBarWidth } = useDashBoardContext()

    return (
        <Box
            sx={{
                backgroundColor: isActive ? '#fff' : '#FF9737',
                width: { xs: '20px' },
                height: { xs: '20px' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                color: isActive ? '#FF9737' : '#fff',
                fontFamily: 'Causten-Semibold',
                fontSize: '14px',

                position: dashboardSideBarWidth ? 'static' : 'absolute',
                top: isActive ? '-8px' : '0px',
                right: isActive ? '-8px' : '0px',
                transitionDuration: '0.5s',
            }}
        >
            {menuItem?.badge}
        </Box>
    )
}
export default UpperMenu