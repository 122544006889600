import React, { useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid, Typography } from '@mui/material'

import { SwipeableAutoCompleteComponent, BlackButtonOne, LanguageCard, AlertPopup } from 'components';
import { useSelector } from 'react-redux';


const ValidationSchema = yup.object().shape({
    language: yup.object().nullable().required("Required!"),
    level: yup.object().nullable().required("Required"),
})


const LanguageFormSection = ({ myLanguages, setMyLanguages }) => {

    const { languagesData } = useSelector(state => state?.languages)
    const { languageLevelsData } = useSelector(state => state?.languageLevels)
    console.log(languagesData, languageLevelsData)
    // const [delPopup, setDelPopup] = useState(false)

    const {
        errors,
        values,
        touched,
        isValid,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        validationSchema: ValidationSchema,
        initialValues: {
            language: null,
            level: null,
        },
        onSubmit: async (values, { resetForm }) => {
            setMyLanguages(prevState => [...prevState, values])
            resetForm()
        }
    })

    const handleDelete = ({ id }) => {
        console.log(id)
        const filteredList = myLanguages.filter(item => item?._id !== id)
        setMyLanguages(filteredList)
    }


    return (
        <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>

            {/* <AlertPopup
                open={!!delPopup}
                handleClose={() => setDelPopup(false)}
                questionText="Are you sure to delete?"
                buttonText="Confirm"
                handleClick={() => {
                    handleDelete({ id: })
                    setDelPopup(false)
                }}
            /> */}

            <Grid item xs={12} sm={5}>
                <SwipeableAutoCompleteComponent
                    name="language"
                    label="language"
                    placeholder="Select language"
                    list={languagesData ?? []}
                    error={touched?.language && errors?.language}
                    value={values?.language}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>

            <Grid item xs={12} sm={5} sx={{ width: '100%', }}>
                <SwipeableAutoCompleteComponent
                    name="level"
                    label="Level"
                    placeholder="Select level"
                    list={languageLevelsData ?? []}
                    error={touched?.level && errors?.level}
                    value={values?.level}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>

            <Grid item xs={12} sm={2}>
                <BlackButtonOne
                    text="Add"
                    color="#FF9737"
                    isButtonActive={isValid}
                    handleClick={handleSubmit}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontFamily: 'Causten-Medium',
                        fontSize: '16px',
                        lineHeight: '19px',
                        color: '#0E1436',
                        mb: { xs: '24px' },
                    }}
                >
                    Your Language(s)
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row', md: 'row' },
                        gap: { xs: '20px' },
                        flexWrap: 'wrap',
                        justifyContent: { xs: 'center', sm: 'left' },
                    }}
                >
                    {
                        myLanguages?.map((item, index) => {
                            return (
                                <LanguageCard
                                    key={index}
                                    item={item}
                                    // handleClick={setSelectedItem}
                                    handleDelete={handleDelete}
                                />
                            )
                        })
                    }
                </Box>
            </Grid>

        </Grid>
    )
}

export default LanguageFormSection