import React, {
    useCallback,
    useState
} from "react";
import { styled } from '@mui/material/styles';
import { Box, Typography, Link } from "@mui/material"
import { Link as RouterLink } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { AlertPopup } from "components";
import { useDashBoardContext } from "layouts/DashboardLayout/DashboardContext/DashboardContext";


const LightTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => null);


const BottomMenu = () => {

    const personalInfo = null
    const [isTooltip, setIsTooltip] = useState(false);
    const [isDelPopup, setIsDelPopup] = useState(false);
    const { currentDashboardPage, dashboardSideMenuList, dashboardSideBarWidth, setCurrentDashboardPage } = useDashBoardContext()

    const handleLogout = () => { }

    const renderBottomMenu = useCallback(() => {

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                    width: '180px'
                }}
            >

                <AlertPopup
                    open={isDelPopup}
                    handleClose={() => setIsDelPopup(false)}
                    questionText="Do you want to logout?"
                    buttonText="Logout"
                    handleClick={handleLogout}
                />

                {
                    dashboardSideMenuList?.bottomMenu?.items?.map(item => (
                        <Box onClick={() => setCurrentDashboardPage(item)} key={item.id}>
                            <Link
                                to={item.link}
                                component={RouterLink}
                                onClick={() => setIsTooltip(false)}
                                sx={{
                                    textDecoration: 'none',
                                    display: 'block',
                                    fontFamily: 'Causten-Medium',
                                    fontSize: '14px',
                                    lineHeight: '16.8px',
                                    p: '5px',
                                    color: currentDashboardPage?.id === item.id ? '#000' : '#7D8898',

                                    '&:hover': {
                                        color: '#000'
                                    },

                                }}
                            >
                                {item.text}
                            </Link>
                        </Box>
                    ))
                }


                <Link
                    onClick={() => setIsDelPopup(true)}
                    sx={{
                        textDecoration: 'none',
                        display: 'block',
                        fontFamily: 'Causten-Medium',
                        fontSize: '14px',
                        lineHeight: '16.8px',
                        color: '#7D8898',
                        cursor: 'pointer',
                        p: '5px',
                        '&:hover': {
                            color: '#000'
                        }
                    }}
                >
                    Logout
                </Link>

            </Box>
        )
    })


    return (
        <LightTooltip
            title={renderBottomMenu()}
            placement="top-end"
            open={isTooltip}
            onClose={() => setIsTooltip(false)}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            TransitionComponent={Zoom}

            sx={{

                '& > .MuiTooltip-tooltip ': {
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    boxShadow: "1px 1px 5px 0px rgba(204, 204, 204, 0.76)",
                    backdropFilter: "blur(8px)",
                    position: 'relative',
                    left: dashboardSideBarWidth ? '50px' : '0',
                    p: "15px 25px",
                    borderRadius: '8px',
                }
            }}
        >

            <Box
                onClick={() => setIsTooltip(!isTooltip)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',

                }}
            >

                <Box
                    sx={{
                        flex: '0 0 48px',
                        backgroundColor: '#E5EAED',
                        width: { xs: '48px' },
                        height: { xs: '48px' },
                        borderRadius: '50%',
                        // backgroundImage: `url(${personalInfo?.personalInformation?.profileImageUrl ? `${personalInfo?.personalInformation?.profileImageUrl}?${isImageUpdated}` : ProfileImage})`,
                        backgroundSize: 'cover',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                />

                {
                    dashboardSideBarWidth && (
                        <Box
                            sx={{
                                width: dashboardSideBarWidth ? '100%' : '0',
                                transitionDurationn: '0.5s',
                                transitionProperty: 'width',
                                overflow: 'hidden',
                            }}
                        >

                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Causten-Regular',
                                        fontSize: { xs: '16px' },
                                        lineHeight: { xs: '19.2px' },
                                        color: '#FFFFFF',
                                        textTransform: 'capitalize',
                                        pl: { xs: '12px' },
                                        mr: { xs: '12px' },
                                        flexGrow: 1,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {
                                        personalInfo?.personalInformation?.fullName ? personalInfo?.personalInformation?.fullName.slice(0, 20) : "Name Surname"
                                    }
                                </Typography>

                                <Box sx={{ color: '#fff', p: { xs: '2px' }, }} >
                                    {isTooltip ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', }} >

                                <Typography
                                    sx={{
                                        fontFamily: 'Causten-Regular',
                                        fontSize: { xs: '12px' },
                                        lineHeight: { xs: '14.4px' },
                                        color: '#FFFFFF',
                                        // width:'100%',
                                        pl: { xs: '12px' },
                                        mr: { xs: '12px' },
                                        whiteSpace: 'nowrap',
                                        // backgroundColor:'red',
                                    }}
                                >
                                    Online
                                </Typography>

                                <Box
                                    sx={{
                                        backgroundColor: '#187700',
                                        width: { xs: '8px' },
                                        height: { xs: '8px' },
                                        borderRadius: '50%',
                                    }}
                                />

                            </Box>

                        </Box>
                    )
                }

            </Box>

        </LightTooltip>
    )
}

export default BottomMenu