import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
  internCertificatesData: null,
  internCertificatesLoading: false,
  internCertificatesError: null,
};


export const internCertificatesFunc = createAsyncThunk("internCertificates/internCertificatesFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Certificates {
      intern {
        cv {
          certificates {
            _id
            name
            organization
            issueDate
            expirationDate
          }
        }
      }
    }`
  });

  var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const internCertificates = createSlice({
  name: "internCertificates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(internCertificatesFunc.pending, (state) => {
      state.internCertificatesLoading = true;
    });
    builder.addCase(internCertificatesFunc.fulfilled, (state, action) => {
      state.internCertificatesLoading = false;
      state.internCertificatesData = action.payload.data?.intern?.cv?.certificates;
      // console.log(action.payload.response);
      state.internCertificatesError = action.payload.error;
      state.internCertificatesError = action.payload?.response?.status;
    });
    builder.addCase(internCertificatesFunc.rejected, (state, action) => {
      state.internCertificatesLoading = false;
      state.internCertificatesError = action.error;
    });
  }
});

export default internCertificates.reducer;