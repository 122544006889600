import React from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid } from '@mui/material';

import {
    TextButtonOne,
    BlackButtonOne,
    FileUploaderComp,
    BasicInputComponent,
    SwipeableAutoCompleteComponent
} from 'components';
import { useOnboardingContext } from 'layouts/OnboardingLayout/OnboardingContext/OnboardingContext';
import { OnboardingLayout } from 'layouts/OnboardingLayout';



const ValidationSchema = yup.object().shape({
    webSite: yup.string()
        // .url()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        )
        .required("Required!"),
    numberOfEmployees: yup.object().nullable()
        .required("Required!"),
    contactEmail: yup.string().email("Must be a valid email!")
        .required("Required!"),
    logo: yup.mixed()
        .required('File is required!'),
})


const UniversityInformationForm2 = () => {

    const { setOnboardingFormNumber, setCurrentPage, onboardingPageList } = useOnboardingContext()

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        // handleSubmit, 
    } = useFormik({
        initialValues: {
            webSite: "",
            numberOfEmployees: null,
            contactEmail: "",
            logo: null,
        },
        validationSchema: ValidationSchema,
        onSubmit: (e) => {
            e.preventDefault();
        }
    })
    // ************************************* formik end *********************

    // const { webSite, numberOfEmployees, contactEmail, logo } = values
    const isButtonAktive = true

    const handleNextStep = () => {
        setCurrentPage(onboardingPageList[1]?.pages[3])
        setOnboardingFormNumber(prevState => prevState + 1)
    }

    const handleSkipStep = () => {
        setCurrentPage(onboardingPageList[1]?.pages[3])
        setOnboardingFormNumber(prevState => prevState + 1)
    }

    const handlePreviousStep = () => {
        setCurrentPage(onboardingPageList[1]?.pages[1])
        setOnboardingFormNumber(prevState => prevState - 1)
    }

    // const handleSaveLogo = () => {

    //     // const imageNameSplitArray = values?.logo?.name?.split('.')

    //     // // console.log(imageNameSplitArray[imageNameSplitArray?.length - 1])

    //     // let data = JSON.stringify({
    //     //     query: `query {
    //     //   imagePreSignedUrls(
    //     //       imageType:logo,
    //     //       contentType:${imageNameSplitArray[imageNameSplitArray?.length - 1] ? "pngImage" : "jpegImage"}
    //     //   ) {
    //     //       presignedUploadUrl
    //     //       publicUrl
    //     //   }
    //     // }`,

    //     //     variables: {},
    //     // });


    //     // let config = {
    //     //     method: 'post',
    //     //     url: `${process.env.REACT_APP_SERVER_BASE_URL_GRAPHQL}`,
    //     //     headers: {
    //     //         'Authorization': `Bearer ${accessToken}`,
    //     //         'Content-Type': 'application/json'
    //     //     },
    //     //     data: data
    //     // };

    //     // axios(config)
    //     //     .then(function (response) {
    //     //         console.log("first response handleSaveLogo", response?.data?.data?.imagePreSignedUrls?.publicUrl);

    //     //         setOnboardingForm({
    //     //             ...onboardingForm,
    //     //             CompanyInformation: {
    //     //                 ...onboardingForm.CompanyInformation,
    //     //                 logoImageUrl: response?.data?.data?.imagePreSignedUrls?.publicUrl,
    //     //             }
    //     //         })

    //     //         let config2 = {
    //     //             method: 'put',
    //     //             url: response?.data?.data?.imagePreSignedUrls?.presignedUploadUrl,
    //     //             headers: {
    //     //                 'x-amz-acl': 'public-read',
    //     //                 'Content-Type': 'image/jpeg'
    //     //             },
    //     //             data: values?.logo
    //     //         };

    //     //         axios(config2)
    //     //             .then(function (response) {
    //     //                 console.log("second response handleSaveLogo", response.data);
    //     //                 setOnBoardingFormPage(formPageList.FinishedPages.fullFinished)
    //     //                 setLoading(false)


    //     //                 handleNextStep()


    //     //             })
    //     //             .catch(function (error) {
    //     //                 console.log("second error handleSaveLogo", error?.response);
    //     //             });

    //     //     })
    //     //     .catch(function (error) {
    //     //         console.log("first error handleSaveLogo", error?.response);
    //     //     });


    // }


    // const [file, setFile] = useState(null);


    // const handleSave = () => {

    //     // const list = file?.name?.split('.')
    //     // const isPngOrJpg = list[list.length - 1]

    //     // handleUploadImageMutation({
    //     //     file: file,
    //     //     rawData: GRAPHQL.Queries.ImagesPreSignedUrls.LogoImagesPreSignQuery(isPngOrJpg),

    //     //     updateCompanyRawData: (url) => GRAPHQL.Mutations.UpdateCompanyInfo.UpdateLogoImage({ logoImageUrl: url ?? "" }),

    //     //     onSuccess: (response) => {
    //     //         console.log('successfull', response?.data?.imagePreSignedUrls?.presignedUploadUrl)
    //     //         setProfileImageBackdrop(false)
    //     //     },
    //     //     onError: (error) => {
    //     //         // console.log('error', error)
    //     //         setProfileImageBackdrop(false)
    //     //     }
    //     // })

    // }


    return (

        <OnboardingLayout>
            <Grid container spacing={5} >

                <Grid item xs={12} lg={6} >

                    <Grid container spacing={5} >

                        <Grid item xs={12}>
                            <BasicInputComponent
                                name="webSite"
                                label="Website"
                                placeholder="Enter your web site"
                                value={values?.webSite}
                                error={touched.webSite && errors.webSite}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <SwipeableAutoCompleteComponent
                                name="numberOfEmployees"
                                label="Number of Employees"
                                placeholder="Select a number of employees"
                                list={[{ _id: 'w', name: '1-5 Employee' }]}
                                values={values?.numberOfEmployees}
                                error={touched.numberOfEmployees && errors.numberOfEmployees}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <BasicInputComponent
                                name="contactEmail"
                                label="Contact Info"
                                placeholder="Enter your email address"
                                error={touched.contactEmail && errors.contactEmail}
                                value={values?.contactEmail}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                            />
                        </Grid>

                    </Grid>

                </Grid>


                <Grid item xs={12} lg={6} >
                    <FileUploaderComp
                        file={values?.logo}
                        setFile={(e) => setFieldValue('logo', e)}
                        type={["JPG", "JPEG", "PNG"]}
                    />
                </Grid>

            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: 3 },
                    justifyContent: 'space-between',
                    mt: '20px',
                }}
            >

                <TextButtonOne onClick={handlePreviousStep} />

                {
                    !isButtonAktive ? (
                        <BlackButtonOne
                            text="Next Step"
                            handleClick={handleNextStep}
                            styles={{ maxWidth: '160px' }}
                            isButtonAktive={true}
                        />
                    ) : (
                        <BlackButtonOne
                            text="Skip Step"
                            handleClick={handleSkipStep}
                            styles={{ maxWidth: '160px' }}
                            isButtonAktive={true}
                        />
                    )
                }

            </Box>
        </OnboardingLayout>
    )
}

export default UniversityInformationForm2