import React, {
    useEffect,
    useState
} from 'react'
import { Box } from '@mui/material';
import { motion } from "framer-motion"


import { UpperLoadingBar } from 'components';
import { AuthImage1, BackgroundImage } from 'assets';
import { ImageSection, AuthNavbar } from 'layouts/AuthLayout';
// import { duration } from 'moment/moment';


const LoadingTime = 2000


const AuthLayout = ({ children, authImage }) => {

    // ############################ when page mount ###############################
    const [pageRefreshLoading, setPageRefreshLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setPageRefreshLoading(false), LoadingTime)
    }, []);
    // #############################################################################

    const container = {
        hidden: {
            opacity: 0.2,
            transition: {
                // delayChildren: 2
                duration: 10,
                delay: 5,
                x: { duration: 1 },
                default: { ease: "linear" }

            }
        },
        show: {
            opacity: 1,
            transition: {
                // delayChildren: 2
                duration: 1.5,
                // x: { duration: 100 },
                default: { ease: "linear" }
            }
        }
    }

    const item = {
        hidden: { opacity: 0.2 },
        show: { opacity: 1 }
    }

    return (
        <motion.div
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // transition={{ duration: 2 }}
            variants={container}
            initial="hidden"
            animate="show"
            style={{
                width: "100%",
            }}
        >
            {pageRefreshLoading && <UpperLoadingBar loadingTime={LoadingTime} />}

            <motion.div variants={item}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: { xs: 3, md: 2, lg: 0 },

                        width: '100%',

                        height: { xs: 'auto', sm: '100vh', lg: '100vh' },
                        minHeight: { xs: 'auto', md: '750px', lg: '900px' },
                        overflow: 'hidden',

                        pb: { xs: '50px', sm: '0px', lg: '0px' },
                        position: 'relative',

                    }}
                >

                    <AuthNavbar />

                    {
                        authImage && (<ImageSection bgImage={authImage || AuthImage1}
                            style={{
                                height: { xs: '100vh', md: '750px', lg: '100vh' },
                                minHeight: { xs: 'auto', md: '750px', lg: '900px' },
                                display: { xs: 'none', md: 'block' },
                                position: 'relative',
                                zIndex: 1,
                            }}
                        />)
                    }

                    <Box //right section
                        sx={{
                            position: 'relative',
                            width: { xs: '100%', md: 'auto', lg: '100%' },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: { xs: 'start', sm: 'center' },

                            height: { xs: 'auto', md: '750px', lg: '100vh' },
                            minHeight: { xs: 'auto', md: '750px', lg: '900px' },

                            px: 2,
                            mt: { xs: '100px', sm: '10px', md: '0px' },
                            zIndex: 1,
                            // backgroundColor: 'green',
                        }}
                    >
                        {children}
                    </Box>

                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            display: { xs: 'none', sm: 'block' },
                            zIndex: 0,
                        }}
                    >
                        <img src={BackgroundImage} alt="" width="100%" height="100%" />
                    </Box>

                </Box>
            </motion.div>

        </motion.div>
    )
}

export default AuthLayout