import React from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid } from '@mui/material'

import {
    TextButtonOne,
    BlackButtonOne,
    BasicInputComponent,
    AutoCompleteCityComponent,
    AutoCompleteCountryComponent,
    SwipeableAutoCompleteComponent,
} from 'components';
import { useOnboardingContext } from 'layouts/OnboardingLayout/OnboardingContext/OnboardingContext';
import { OnboardingLayout } from 'layouts/OnboardingLayout';



const ValidationSchema = yup.object().shape({
    companyName: yup.string()
        .required("Required!"),
    industry: yup.object().nullable()
        .required("Required!"),
    city: yup.object().nullable()
        .required("Required!"),
    country: yup.object().nullable()
        .required("Required!"),
})


const CompanyInformationForm1 = () => {

    const { setOnboardingFormNumber, setCurrentPage, onboardingPageList } = useOnboardingContext()

    // ************************************* formik  start *********************
    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        // handleSubmit,
    } = useFormik({

        initialValues: {
            companyName: "",
            industry: null,
            city: null,
            country: null,
        },

        validationSchema: ValidationSchema,
        onSubmit: (e) => {
            e.preventDefault();
        }

    })
    // ************************************* formik end *********************

    const { companyName, industry, city, country } = values

    const handleNextStep = () => {
        setCurrentPage(onboardingPageList[1]?.pages[2])
        setOnboardingFormNumber(prevState => prevState + 1)
    }

    const handleSkipStep = () => {
        setCurrentPage(onboardingPageList[1]?.pages[2])
        setOnboardingFormNumber(prevState => prevState + 1)
    }

    const handlePreviousStep = () => {
        setCurrentPage(onboardingPageList[0]?.pages[0])
        setOnboardingFormNumber(prevState => prevState - 2)
    }

    const isButtonAktive = (
        companyName !== "" &&
        industry !== "" &&
        (country?._id !== "" && country !== "") &&
        (city?._id !== "" && city !== "")
    )

    return (
        <OnboardingLayout>

            <Grid
                container
                spacing={3}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // mt: { xs: '40px' },
                    // width: { xs: 'auto', sm: 'auto', md: '450px', lg: '775px' },
                    // backgroundColor:'blue',
                }}
            >

                <Grid item xs={12} lg={6}>
                    <BasicInputComponent
                        name="companyName"
                        label="Company Name"
                        placeholder="Enter company name"

                        value={values?.companyName}
                        error={touched.companyName && errors.companyName}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <SwipeableAutoCompleteComponent
                        name="industry"
                        label="Industry"
                        placeholder="Select your industry"
                        list={[{ _id: 'w', name: 'industry' }]}

                        error={touched.industry && errors.industry}
                        values={values?.industry}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <AutoCompleteCountryComponent
                        error={touched.country && errors.country}
                        values={values?.country}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <AutoCompleteCityComponent
                        countryId={values?.country?._id}
                        error={touched.city && errors.city}
                        values={values?.city}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: 3 },
                    justifyContent: 'space-between',
                    mt: '20px',
                }}
            >

                <TextButtonOne onClick={handlePreviousStep} />

                {
                    !isButtonAktive ? (
                        <BlackButtonOne
                            text="Next Step"
                            handleClick={handleNextStep}
                            styles={{ maxWidth: '160px' }}
                            isButtonAktive={true}
                        />
                    ) : (
                        <BlackButtonOne
                            text="Skip Step"
                            handleClick={handleSkipStep}
                            styles={{ maxWidth: '160px' }}
                            isButtonAktive={true}
                        />
                    )
                }

            </Box>

        </OnboardingLayout>
    )
}

export default CompanyInformationForm1