import React, {
    useEffect,
    useState
} from 'react'
import { styled } from '@mui/material/styles';
import { Autocomplete, Box, Button, IconButton, SwipeableDrawer, TextField, Typography } from '@mui/material'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useDispatch, useSelector } from 'react-redux';
import { citiesFunc } from 'redux/parameters/cities';



const drawerBleeding = 56;
const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: '#ccc',//theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));


const AutoCompleteCityComponent = ({
    label = "City",
    placeholder = "Enter your city",
    error,
    isActive,

    values,
    setFieldValue,
    handleBlur,

    countryId,
}) => {

    const dispatch = useDispatch()
    const cities = useSelector(state => state?.cities?.citiesData)
    const localUser = localStorage.getItem("userAuthInfo") ? JSON.parse(localStorage.getItem("userAuthInfo")) : null
    // console.log(values)


    // const [cities, setCities] = useState([])
    const [bottomDrawer, setBottomDrawer] = useState(false)

    const [value, setValue] = useState(values ?? null);
    const [inputValue, setInputValue] = useState(value?.name ?? "")

    useEffect(() => setValue(values ?? null), [values])
    useEffect(() => setInputValue(value?.name ?? ""), [value])

    useEffect(() => {

        // console.log("city input useEffect", values, value)

        if (countryId) {
            dispatch(citiesFunc({ countryId, accessToken: localUser?.accessToken }))
            // setCities([
            //     { _id: '1', name: 'izmir' },
            //     { _id: '2', name: 'istanbul' },
            // ])

            //     var data = JSON.stringify({
            //         query: `
            //         query Cities($countryId: ID!) {
            //             cities(countryId: $countryId) {
            //               count
            //               data {
            //                 _id
            //                 name
            //                 code
            //                 createdAt
            //                 updatedAt
            //               }
            //             }
            //           }
            //         `,
            //         variables: {
            //             "countryId":countryId
            //         }
            //     });

            //     var config = {
            //         method: 'post',
            //         url: process.env.REACT_APP_SERVER_BASE_URL_GRAPHQL,
            //         headers: {
            //             'Authorization': `Bearer ${accessToken}`,
            //             'Content-Type': 'application/json'
            //         },
            //         data: data
            //     };

            //     axios(config)
            //         .then(function (response) {
            //             // console.log(response.data);
            //             setCities(response?.data?.data?.cities?.data)
            //         })
            //         .catch(function (error) {
            //             console.log(error);
            //             console.log("city error");
            //         });
        } else {
            // setValue(null)
            // setFieldValue("city", null)
        }

        // setValue(null)
        // setFieldValue("city", null)

    }, [countryId, setFieldValue])


    const renderAutoComplete = (value) => (

        <Autocomplete
            id="city"
            readOnly={!countryId}
            // autoComplete={false}
            options={cities ?? []}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}

            value={value}
            onChange={(event, newValue) => {
                setValue(newValue)
                setFieldValue("city", newValue)
            }}

            inputValue={inputValue} //initialstate verilince inputun içini doldurması için verilmeli

            sx={{
                width: '100%',
                p: '0px!important',

                '& > div > div > fieldset': {
                    border: 'none'
                },
            }}

            renderOption={(props, option) => {

                // console.log(option)
                return (
                    <Box
                        component="li"
                        {...props}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            py: "0px!important",
                            my: "0!important",
                            backgroundColor: '#fff !important',

                            '&:hover *': {
                                color: '#0E1436!important'
                            },

                        }}
                    >
                        <Box
                            sx={{
                                '& > img': { mr: 2, flexShrink: 0 },
                                '& > p': { mr: 2, flexShrink: 1, },
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                //  backgroundColor: 'red',
                                borderBottom: '1px solid #D1D5D8',
                                height: '48px',
                            }}
                        >


                            <Typography
                                sx={{
                                    width: '100%',
                                    fontFamily: 'Causten-SemiBold',
                                    fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                                    lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                                    color: value?.name === option.name ? '#0E1436' : '#D1D5D8',
                                }}
                            >
                                {option.name}
                            </Typography>

                            <Box
                                sx={{
                                    border: `1px solid ${value?.name === option.name ? '#0E1436' : '#666'}`,
                                    p: '2px',
                                    borderRadius: '50%',

                                    width: '20px',
                                    height: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '10px',
                                        backgroundColor: value?.name === option.name ? '#0E1436' : '#fff'
                                    }}
                                />
                            </Box>

                        </Box>
                    </Box>
                )
            }}

            renderInput={(params) => {

                return (
                    <TextField
                        {...params}
                        onBlur={handleBlur}
                        placeholder={placeholder}

                        inputProps={{
                            ...params.inputProps,
                            sx: { textTransform: 'capitalize', width: '100%' }
                        }}
                    />
                )
            }}

        />

    )


    return (
        <Box sx={{ position: 'relative' }}>

            <Typography
                sx={{
                    fontFamily: 'Causten-SemiBold',
                    fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                    lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                    color: value?.name ? '#96A1BA' : '#0E1436',
                    mb: '5px',
                    textTransform: 'capitalize',
                }}
            >
                {label}
            </Typography>

            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>

                <Box
                    sx={{
                        borderRadius: '8px',
                        border: error ? '1px solid #db0404' : '1px solid #d8d4d1',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '48px',
                        backgroundColor: '#fff',
                    }}
                >
                    <Button
                        onClick={() => setBottomDrawer(true)}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            px: '16px',
                            height: '100%',
                            fontFamily: 'Causten-SemiBold',
                            fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                            lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                            color: values?.name ? 'rgba(14, 20, 54, 1)' : 'rgba(14, 20, 54, 0.35)',
                            textTransform: 'capitalize',
                        }}
                    >


                        {values?.name !== "" ? values?.name : placeholder}

                        <ArrowDropDownIcon sx={{ color: value?.name ? 'rgba(14, 20, 54, 1)' : 'rgba(14, 20, 54, 0.203)' }} />

                    </Button>
                </Box>

                <SwipeableDrawer
                    anchor="bottom"
                    open={bottomDrawer}
                    onClose={() => setBottomDrawer(false)}
                    onOpen={() => setBottomDrawer(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}

                    sx={{
                        '& > div': {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                        },
                        zIndex: (theme) => theme.zIndex.drawer + 10,
                    }}
                >

                    <Box> {/* ############################# upper Container ############################# */}

                        <Puller />

                        <Box
                            sx={{
                                position: 'relative',
                                height: '50px',
                                mt: { xs: '15px' },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >

                            <IconButton
                                onClick={() => setBottomDrawer(false)}
                                sx={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                }}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>

                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: 'Causten-Regular',
                                    fontSize: '18px',
                                    lineHeight: '100%',
                                    color: '#202020!important',
                                }}
                            >
                                Selection
                            </Typography>

                        </Box>

                    </Box>

                    <Box // ############################# list Container #############################
                        sx={{
                            px: 2,
                            pb: 2,
                            minHeight: '300px',
                            maxHeight: '600px',
                        }}
                    >
                        {renderAutoComplete()}
                    </Box>

                </SwipeableDrawer>

            </Box>

            <Box
                sx={{
                    borderRadius: '8px',
                    border: error ? '1px solid #db0404' : '1px solid #d8d4d1',
                    overflow: 'hidden',
                    display: { xs: 'none', sm: 'flex' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '48px',
                    backgroundColor: '#fff',
                }}
            >
                {renderAutoComplete()}
            </Box>


            <Typography
                sx={{
                    color: '#db0404',
                    fontFamily: 'Causten-SemiBold',
                    fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                    lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                    position: 'absolute',
                    bottom: '-20px',
                }}
            >
                {error}
            </Typography>

        </Box>
    )
}

export default AutoCompleteCityComponent