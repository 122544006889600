import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    onboardCertificatesData: null,
    onboardCertificatesLoading: false,
    onboardCertificatesError: null,
    certificatesArray: [],
};



export const onboardCertificatesFunc = createAsyncThunk("onboardCertificates/onboardCertificatesFunc", async (values) => {

    console.log(values);
    var data1 = JSON.stringify({
        query: `mutation OnboardCertificates($certificatesInput: [CertificateInput!]!) {
            onboardCertificates(certificatesInput: $certificatesInput) {
              success
            }
          }`,
        variables: {
            "certificatesInput": values.certificatesArrayForDispatch
          }
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        console.log(err.response.data);
        return err;
    });

    const data = await response;
    return data;
});


const onboardCertificates = createSlice({
    name: "onboardCertificates",
    initialState,
    reducers: {
        setCertificatedArray: (state, action) => {
            state.certificatesArray = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(onboardCertificatesFunc.pending, (state) => {
            state.onboardCertificatesLoading = true;
        });
        builder.addCase(onboardCertificatesFunc.fulfilled, (state, action) => {
            state.onboardCertificatesLoading = false;
            state.onboardCertificatesData = action.payload.data.onboardCertificates;
            state.onboardCertificatesError = action.payload.errors;
            console.log(action.payload.data);
        });
        builder.addCase(onboardCertificatesFunc.rejected, (state, action) => {
            state.onboardCertificatesLoading = false;
            state.onboardCertificatesError = action.error.message;
        });
    }
});

export const {
    setCertificatedArray
} = onboardCertificates.actions;


export default onboardCertificates.reducer;