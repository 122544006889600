import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    universitiesData: [],
    universitiesLoading: false,
    universitiesError: null,
};


export const universitiesFunc = createAsyncThunk("universities/universitiesFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Data($filter: FilterParameterInput) {
            parameters(filter: $filter) {
              universities {
                data {
                  _id
                  name
                }
              }
            }
          }`,
        variables: {
            "filter": {
                "name": values.filterUniversity
            }
        }
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const universities = createSlice({
    name: "universities",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(universitiesFunc.pending, (state) => {
            state.universitiesLoading = true;
        });
        builder.addCase(universitiesFunc.fulfilled, (state, action) => {
            state.universitiesLoading = false;
            state.universitiesData = action.payload.data.parameters.universities.data;
        });
        builder.addCase(universitiesFunc.rejected, (state, action) => {
            state.universitiesLoading = false;
            state.universitiesError = action.error.message;
        });
    }
});

export default universities.reducer;