const initialConfig = (url, data, accessToken) => ({
    method: 'post',
    url: url,
    headers: {
        'Authorization': 'Bearer ' + accessToken,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    },
    data: data
});

export default initialConfig