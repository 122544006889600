import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
  internSkillsData: null,
  internSkillsLoading: false,
  internSkillsError: null,
};


export const internSkillsFunc = createAsyncThunk("internSkills/internSkillsFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Skills {
      intern {
        cv {
          skills {
            _id
            name
          }
        }
      }
    }`
  });

  var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const internSkills = createSlice({
  name: "internSkills",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(internSkillsFunc.pending, (state) => {
      state.internSkillsLoading = true;
    });
    builder.addCase(internSkillsFunc.fulfilled, (state, action) => {
      state.internSkillsLoading = false;
      state.internSkillsData = action.payload.data?.intern?.cv?.skills;
      state.internSkillsError = action.payload.errors;
    });
    builder.addCase(internSkillsFunc.rejected, (state, action) => {
      state.internSkillsLoading = false;
      state.internSkillsError = action.error;
    });
  }
});

export default internSkills.reducer;