import React from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid } from '@mui/material'

import {
    TextButtonOne,
    BlackButtonOne,
    DatePickerComponent,
    BasicInputComponent,
    AutoCompleteCityComponent,
    AutoCompleteCountryComponent,
    SwipeableAutoCompleteComponent,
} from 'components';
import { OnboardingLayout } from 'layouts/OnboardingLayout'
import { useOnboardingContext } from 'layouts/OnboardingLayout/OnboardingContext/OnboardingContext';



const PersonalValidationSchema = yup.object().shape({
    fullName: yup.string().matches(/^[aA-zZğĞüÜşŞöÖçÇıİ\s]+$/, "Only alphabets are allowed for this field!").required("Required!"),
    birthDate: yup.date().required("Required!"),
    country: yup.object().nullable().required("Required!"),
    city: yup.object().nullable().required("Required!"),
    department: yup.object().nullable().required("Required!"),
    position: yup.object().nullable().required("Required!"),
})


const PersonalInformationForm = () => {


    // const { handleMutation } = GRAPHQL.useMutationContext()
    const { setCurrentPage, onboardingPageList, setOnboardingFormNumber } = useOnboardingContext()

    const {
        errors,
        values,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        // handleSubmit,
    } = useFormik({

        initialValues: {
            fullName: "",
            birthDate: "",
            country: "",
            city: "",
            department: "",
            position: "",
        },
        validationSchema: PersonalValidationSchema,
        onSubmit: (e) => {
            e.preventDefault();
        }

    })

    // const isButtonAktive = (
    //     values.fullName &&
    //     values.birthDate &&
    //     values.country &&
    //     values.city &&
    //     values.department &&
    //     values.position
    // )

    const handleBackStep = () => {

    }

    const handleNextStep = () => {

        setOnboardingFormNumber(prevState => prevState + 1)
        setCurrentPage(onboardingPageList[1]?.pages[0])
        // setOnboardingFormNumber(2)

        // handleMutation({
        //     rawData: GRAPHQL.Mutations.OnboardingMutations.OnboardAdminInformation({ values }),
        //     onSuccess: (data) => {
        //         console.log(data)
        //         // setOnBoardingFormPage(formPageList.CompanyInformation.firstPage)
        //     },
        //     onError: (err) => {
        //         console.log(err)
        //     }
        // })

    }


    return (
        <OnboardingLayout>

            <Grid container spacing={3} >

                <Grid item xs={12} lg={6}>
                    <BasicInputComponent
                        name="fullName"
                        label="Full Name"
                        placeholder="Please write your full name"
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        value={values?.fullName}
                        labelfade={values?.fullName}
                        error={touched?.fullName && errors?.fullName}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <DatePickerComponent
                        label="Birth Date"
                        name="birthDate"
                        placeholder="Please enter your birth date"
                        error={touched?.birthDate && errors?.birthDate}
                        value={values?.birthDate}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <SwipeableAutoCompleteComponent
                        name="department"
                        label="department"
                        list={[{ _id: 'w', name: 'department' }]}
                        placeholder="Select your department"
                        values={values?.department}
                        error={touched?.department && errors?.department}

                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <SwipeableAutoCompleteComponent
                        name="position"
                        label="position"
                        list={[{ _id: 'w', name: 'position' }]}
                        placeholder="Select your position"
                        values={values?.position}
                        error={touched?.position && errors?.position}

                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <AutoCompleteCountryComponent
                        error={touched?.country && errors?.country}
                        values={values?.country}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />

                </Grid>

                <Grid item xs={12} lg={6}>
                    <AutoCompleteCityComponent
                        countryId={values?.country?._id}
                        error={touched?.city && errors?.city}
                        values={values?.city}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: 3 },
                    justifyContent: 'space-between',
                    mt: '20px',
                }}
            >
                <TextButtonOne
                    text="Skip this step"
                    onClick={handleBackStep}
                />

                <BlackButtonOne
                    text="Next Step"
                    handleClick={handleNextStep}
                    styles={{ maxWidth: '160px' }}
                    // isButtonAktive={isButtonAktive}
                    isButtonAktive={true}
                />
            </Box>

        </OnboardingLayout>
    )
}

export default PersonalInformationForm