import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, IconButton, Link, Typography } from '@mui/material'

import { SecurityIcon, SuccessIcon } from 'assets'


const BasicInputComponent = ({
    name = "name",
    type = "text",
    label = "Name",
    placeholder = "Enter your name",

    path = "",
    rightText = "",

    children = <></>,

    error = false,
    value = "",
    handleBlur = () => null,
    handleChange = () => null,

    showPasswordIcon = false,
    dashboard,
}) => {

    const [showPassword, setShowPassword] = useState(false)

    return (
        <Box sx={{ position: 'relative' }}>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: '5px',
                }}
            >
                <Typography
                    sx={{

                        fontFamily: 'Causten-SemiBold',
                        fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                        lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                        color: value !== "" ? '#96A1BA' : '#0E1436',
                    }}
                >
                    {label}
                </Typography>

                {
                    path && (
                        <Link component={RouterLink} to={path}
                            sx={{
                                fontFamily: 'Causten-SemiBold',
                                fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                                lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                                color: '#3BA1EB',
                                textDecoration: 'none',
                            }}
                        >
                            {rightText}
                        </Link>
                    )
                }
            </Box>

            <Box
                sx={{
                    borderRadius: '8px',
                    border: error ? '1px solid #db0404' : `1px solid ${dashboard ? '#E5EAED' : '#d8d4d1'}`,
                    // boxShadow: 'none',
                    overflow: 'hidden',
                    // marginBottom: '5px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // p:"4px"
                    backgroundColor: '#fff',
                    // maxHeight: '48px',
                    height: '48px',
                }}
            >
                <input
                    name={name}
                    type={(type && (type !== "password")) ? type : (showPassword ? "text" : "password")}
                    className="input_placeholder"
                    placeholder={placeholder}
                    style={{
                        // borderRadius: '8px',
                        border: 'none',
                        outline: 'none',
                        //    height: '48px' ,
                        width: '100%',
                        fontSize: '14px',
                        lineHeight: '24px',
                        paddingLeft: '16px',
                        paddingBottom: '10px',
                        paddingTop: '10px',
                        fontFamily: 'Causten-SemiBold',
                        color: '#0E1436',
                        // ml:"18px"
                    }}

                    value={value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />

                {
                    showPasswordIcon && (
                        <Box sx={{ px: "12px" }} >
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}>
                                {
                                    error
                                        ?
                                        (<img src={SecurityIcon} alt="" />)
                                        :
                                        (<img src={SuccessIcon} alt="" />)
                                }
                            </IconButton>
                        </Box>
                    )
                }

                {
                    !showPasswordIcon && children
                }
            </Box>

            {
                error && (
                    <Typography
                        sx={{
                            color: '#db0404',
                            fontSize: '14px',
                            lineHeight: '24px',
                            fontFamily: 'Causten-SemiBold',
                            position: 'absolute',
                            bottom: '-25px',
                        }}
                    >
                        {error}
                    </Typography>
                )
            }

        </Box>
    )
}

export default BasicInputComponent