import React, {
    useEffect,
    useState
} from 'react'
import { styled } from '@mui/material/styles';
import { Autocomplete, Box, Button, IconButton, SwipeableDrawer, TextField, Typography } from '@mui/material'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';



const drawerBleeding = 56;
const Puller = styled(Box)(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: '#ccc',//theme.palette.mode === 'light' ? grey[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));


const SwipeableAutoCompleteComponent = ({
    label = "label",
    placeholder = "placeholder",
    error,
    name,
    list,

    values,
    setFieldValue = () => null,
    handleBlur = () => null,
    handleClick = () => null, // setFieldValue yokda normal fonksiyon için
}) => {

    const [bottomDrawer, setBottomDrawer] = useState(false)

    const [value, setValue] = useState(values ?? null);
    const [inputValue, setInputValue] = useState(value?.name ?? "")

    useEffect(() => setValue(values ?? null), [values])
    useEffect(() => setInputValue(value?.name ?? ""), [value])

    const renderAutoComplete = (value) => (
        <Autocomplete
            id={name}
            autoComplete={false}
            options={list ?? []}
            getOptionLabel={(option) => option?.name ? option?.name : ""}
            isOptionEqualToValue={(option, value) => value?.id ? option?.id === value?.id : true}

            value={value}

            onChange={(event, newValue) => {
                setValue(newValue)
                setFieldValue(name, newValue)
                handleClick(newValue)
            }}
            // inputValue={inputValue}

            sx={{
                width: '100%',
                backgroundColor: '#fff',

                '& > div > div > fieldset': {
                    border: 'none'
                },
            }}

            renderOption={(props, option) => {
                // console.log(option)
                return (
                    <Box
                        component="li"
                        {...props}
                        key={option?._id ?? option?.name}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            py: "0px!important",
                            my: "0!important",
                            backgroundColor: '#fff !important',

                            '&:hover *': {
                                color: '#0E1436!important'
                            },
                        }}
                    >
                        <Box
                            sx={{
                                '& > img': { mr: 2, flexShrink: 0 },
                                '& > p': { mr: 2, flexShrink: 1, },
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderBottom: '1px solid #D1D5D8',
                                height: '48px',
                            }}
                        >

                            <Typography
                                sx={{
                                    width: '100%',
                                    fontFamily: 'Causten-SemiBold',
                                    fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                                    lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                                    color: inputValue === option?.name ? '#0E1436' : '#D1D5D8',
                                }}>
                                {option?.name}
                            </Typography>

                            <Box
                                sx={{
                                    border: `1px solid ${inputValue === option?.name ? '#0E1436' : '#666'}`,
                                    p: '2px',
                                    borderRadius: '50%',

                                    width: '20px',
                                    height: '18px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '10px',
                                        backgroundColor: inputValue === option?.name ? '#0E1436' : '#fff'
                                    }}
                                />
                            </Box>

                        </Box>

                    </Box>
                )
            }}

            renderInput={(params) => {

                return (
                    <TextField
                        {...params}
                        fullWidth
                        onBlur={handleBlur}

                        inputProps={{
                            ...params.inputProps,
                            placeholder: placeholder,
                        }}
                    />
                )
            }}
        />
    )

    return (
        <Box sx={{ position: 'relative' }}>

            <Typography
                sx={{
                    fontFamily: 'Causten-SemiBold',
                    fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                    lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                    color: values?.name ? '#96A1BA' : '#0E1436',
                    mb: '5px',
                    textTransform: 'capitalize',
                }}
            >
                {label}
            </Typography>



            <Box sx={{ display: { xs: 'block', sm: 'none' }, }} >

                <Box
                    sx={{
                        borderRadius: '8px',
                        border: error ? '1px solid #db0404' : '1px solid #d8d4d1',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '48px',
                        backgroundColor: '#fff',
                    }}
                >
                    <Button
                        onClick={() => setBottomDrawer(true)}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            px: '16px',
                            height: '100%',
                            fontFamily: 'Causten-SemiBold',
                            fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                            lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                            color: inputValue ? 'rgba(14, 20, 54, 1)' : 'rgba(14, 20, 54, 0.35)',
                            textTransform: 'capitalize',
                        }}
                    >


                        {inputValue !== "" ? inputValue : placeholder}

                        <ArrowDropDownIcon sx={{ color: inputValue ? 'rgba(14, 20, 54, 1)' : 'rgba(14, 20, 54, 0.203)' }} />

                    </Button>
                </Box>

                <SwipeableDrawer
                    anchor="bottom"
                    open={bottomDrawer}
                    onClose={() => setBottomDrawer(false)}
                    onOpen={() => setBottomDrawer(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}

                    sx={{
                        '& > div': {
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                        },
                        zIndex: (theme) => theme.zIndex.drawer + 10,
                    }}
                >

                    <Box> {/* ############################# upper Container ############################# */}

                        <Puller />

                        <Box
                            sx={{
                                position: 'relative',
                                height: '50px',
                                mt: { xs: '15px' },
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >

                            <IconButton
                                onClick={() => setBottomDrawer(false)}
                                sx={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                }}
                            >
                                <ArrowBackIosNewRoundedIcon />
                            </IconButton>

                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontFamily: 'Causten-Regular',
                                    fontSize: '18px',
                                    lineHeight: '100%',
                                    color: '#202020!important',
                                }}
                            >
                                Selection
                            </Typography>

                        </Box>

                    </Box>

                    <Box // ############################# list Container #############################
                        sx={{
                            px: 2,
                            pb: 2,
                            minHeight: '300px',
                            maxHeight: '600px',
                        }}
                    >
                        {renderAutoComplete()}
                    </Box>

                </SwipeableDrawer>

            </Box>

            <Box
                sx={{
                    borderRadius: '8px',
                    border: error ? '1px solid #db0404' : '1px solid #d8d4d1',
                    overflow: 'hidden',
                    display: { xs: 'none', sm: 'flex' },
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '48px',
                    backgroundColor: '#fff',
                }}
            >
                {renderAutoComplete()}
            </Box>



            <Typography
                sx={{
                    color: '#db0404',
                    fontFamily: 'Causten-SemiBold',
                    fontSize: { xs: '14px', sm: '14px', md: '14px', lg: '14px' },
                    lineHeight: { xs: '16px', sm: '16px', md: '16px', lg: '16px' },
                    position: 'absolute',
                    bottom: '-20px',
                }}
            >
                {error}
            </Typography>

        </Box>
    )
}

export default SwipeableAutoCompleteComponent