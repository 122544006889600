import { Button } from "@mui/material"

const BlackButtonOne = ({ isButtonAktive = true, handleClick, text, styles, color = "#191922" }) => {


    return (
        <Button
            type="submit"
            onClick={handleClick}
            disabled={!isButtonAktive}
            sx={{
                backgroundColor: isButtonAktive ? color : "#E3E7F2",
                color: isButtonAktive ? '#FFFF' : '#96A1BA',

                ':hover': {
                    backgroundColor: isButtonAktive ? color : "#E3E7F2",
                    opacity: isButtonAktive ? '0.9' : 1,

                    transform: 'scale(1.005)',
                },

                textTransform: 'none',
                py: { xs: 1, md: 2 },
                px: { xs: '30px' },
                // minWidth: {xs:'100%', sm:'160px'},
                width: { xs: '100%', sm: '100%' },

                height: '48px',
                whiteSpace: 'nowrap',

                fontSize: '14px',
                lineHeight: '16px',
                fontFamily: 'Causten-SemiBold',
                borderRadius: '8px',

                ...styles,

            }}
        >
            {text ? text : "Button"}
        </Button>
    )

}

export default BlackButtonOne