import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    onboardSkillsData: null,
    onboardSkillsLoading: false,
    onboardSkillsError: null,
};



export const onboardSkillsFunc = createAsyncThunk("onboardSkills/onboardSkillsFunc", async (values) => {

    console.log(values.selectedSkillsForDispatch);
    var data1 = JSON.stringify({
        query: `mutation OnboardSkills($skillsInput: [ParameterInput!]!) {
            onboardSkills(skillsInput: $skillsInput) {
              success
            }
          }`,
        variables: {
            "skillsInput": values.selectedSkillsForDispatch
          }
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        console.log(err.response.data);
        return err;
    });

    const data = await response;
    return data;
});


const onboardSkills = createSlice({
    name: "onboardSkills",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(onboardSkillsFunc.pending, (state) => {
            state.onboardSkillsLoading = true;
        });
        builder.addCase(onboardSkillsFunc.fulfilled, (state, action) => {
            state.onboardSkillsLoading = false;
            state.onboardSkillsData = action.payload.data;
            console.log(action.payload.errors);
        });
        builder.addCase(onboardSkillsFunc.rejected, (state, action) => {
            state.onboardSkillsLoading = false;
            state.onboardSkillsError = action.error.message;
        });
    }
});

export const {
    setExperiencesArray
} = onboardSkills.actions;


export default onboardSkills.reducer;