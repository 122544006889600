import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";
import { useSelector } from "react-redux";

const initialState = {
    countriesData: [],
    countriesLoading: false,
    countriesError: null,
};





export const countriesFunc = createAsyncThunk("countries/countriesFunc", async (values) => {


    // console.log(values);
    var data1 = JSON.stringify({
        query: `query Countries($filter: FilterParameterInput, $pagination: Pagination) {
            countries(filter: $filter, pagination: $pagination) {
              data {
                _id
                name
                code
              }
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const countries = createSlice({
    name: "countries",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(countriesFunc.pending, (state) => {
            state.countriesLoading = true;
        });
        builder.addCase(countriesFunc.fulfilled, (state, action) => {
            state.countriesLoading = false;
            // console.log(action.payload)
            state.countriesData = action.payload.data?.countries?.data;
            state.countriesError = action.payload.errors;
        });
        builder.addCase(countriesFunc.rejected, (state, action) => {
            console.log(action.error)
            state.countriesLoading = false;
            state.countriesError = action.error;
        });
    }
});

export default countries.reducer;