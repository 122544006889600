import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup';
import { Box, Grid } from '@mui/material';

import {
    BlackButtonOne,
    WhiteButtonOne,
    ReactQuillEditor,
    BasicInputComponent,
    DatePickerComponent,
    AutoCompleteCityComponent,
    AutoCompleteCountryComponent,
    SwipeableAutoCompleteComponent,
} from 'components';



const ValidationSchema = yup.object().shape({
    companyName: yup.string()
        .required("Required!"),
    position: yup.object().nullable()
        .required("Required!"),
    positionDetail: yup.string()
        .required("Required!"),
    country: yup.object().nullable()
        .required("Required!"),
    city: yup.object().nullable()
        .required("Required!"),
    startDate: yup.string()
        .required("Required!"),
    endDate: yup.string()
        .required("Required!"),
    stillAttending: yup.boolean()
        .required("Required!"),
})

const ExperienceFormSection = ({
    setShowForm,
    handleAddExperience,
    handleUpdate,
    selectedItem,

    showForm,
}) => {


    const {
        errors,
        values,
        touched,
        isValid,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyName: selectedItem?.companyName ?? "",
            position: selectedItem?.position ?? "",
            positionDetail: selectedItem?.positionDetail ?? "",
            country: selectedItem?.country ?? null,
            city: selectedItem?.city ?? null,
            startDate: selectedItem?.startDate ?? "",
            endDate: selectedItem?.endDate ?? "",
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {

            if (selectedItem) {
                handleUpdate({ values, id: selectedItem?._id })
            } else {
                handleAddExperience({ values })
            }
            setShowForm(false)

        }
    })

    console.log(isValid, values)

    return (
        <Grid container spacing={3} sx={{ my: { xs: '30px' } }}>

            <Grid item xs={12} sm={6}>
                <BasicInputComponent
                    name="companyName"
                    label="Company Name"
                    placeholder="Enter certificate company name"
                    error={touched?.companyName && errors?.companyName}
                    value={values?.companyName}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <SwipeableAutoCompleteComponent
                    name="position"
                    label="Position"
                    placeholder="Select position"
                    error={touched?.position && errors?.position}
                    values={values?.position}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <AutoCompleteCountryComponent
                    // name="companyName"
                    // label="Company Name"
                    // placeholder="Enter certificate company name"
                    error={touched?.country && errors?.country}
                    value={values?.country}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <AutoCompleteCityComponent
                    error={touched?.city && errors?.city}
                    value={values?.city}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DatePickerComponent
                    name="startDate"
                    label="Start Date"
                    placeholder="Select date"
                    error={touched?.startDate && errors?.startDate}
                    value={values?.startDate}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}

                    minDate="1990-1-1"
                    maxDate={new Date().getTime()}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DatePickerComponent
                    name="endDate"
                    label="Finish Date"
                    placeholder="Select date"
                    error={touched?.endDate && errors?.endDate}
                    value={values?.endDate}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    minDate={values?.startDate}
                    maxDate={new Date().getTime()}
                />
            </Grid>

            <Grid item xs={12}>
                <ReactQuillEditor
                    className="react_quill_mystyle"
                    errors={errors}
                    values={values}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>

            <Grid item xs={12} >

                <Box
                    sx={{
                        width: { sm: '50%' },
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'flex-end',
                        my: { xs: '40px' },
                        gap: { xs: '10px' },
                    }}
                >

                    <WhiteButtonOne
                        text="Cancel"
                        handleClick={() => setShowForm(false)}
                        isButtonAktive={true}
                    />

                    <BlackButtonOne
                        text={`${selectedItem ? "Update" : "Add"} Experiences`}
                        handleClick={handleSubmit}
                        isButtonAktive={isValid}
                    />

                </Box>

            </Grid>


        </Grid>

    )
}


export default ExperienceFormSection