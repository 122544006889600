import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
  internExperiencesData: null,
  internExperiencesLoading: false,
  internExperiencesError: null,
};


export const internExperiencesFunc = createAsyncThunk("internExperiences/internExperiencesFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Experiences {
          intern {
            cv {
              experiences {
                _id
                companyName
                position {
                  _id
                  name
                }
                positionDetail
                country {
                  _id
                  name
                }
                city {
                  _id
                  name
                }
                startDate
                endDate
              }
            }
          }
        }`
  });

  var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const internExperiences = createSlice({
  name: "internExperiences",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(internExperiencesFunc.pending, (state) => {
      state.internExperiencesLoading = true;
    });
    builder.addCase(internExperiencesFunc.fulfilled, (state, action) => {
      state.internExperiencesLoading = false;
      state.internExperiencesData = action.payload.data?.intern?.cv?.experiences;
      state.internExperiencesError = action.payload.errors;
    });
    builder.addCase(internExperiencesFunc.rejected, (state, action) => {
      state.internExperiencesLoading = false;
      state.internExperiencesError = action.error;
    });
  }
});

export default internExperiences.reducer;