import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    onboardLanguagesData: null,
    onboardLanguagesLoading: false,
    onboardLanguagesError: null,
    languagesArray: [],
};



export const onboardLanguagesFunc = createAsyncThunk("onboardLanguages/onboardLanguagesFunc", async (values) => {

    console.log(values);
    var data1 = JSON.stringify({
        query: `mutation OnboardForeignLanguages($foreignLanguagesInput: [ForeignLanguageInput!]!) {
            onboardForeignLanguages(foreignLanguagesInput: $foreignLanguagesInput) {
              success
            }
          }`,
        variables: {
            "foreignLanguagesInput": values.languageArrayForDispatch
          }
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        console.log(err.response.data);
        return err;
    });

    const data = await response;
    return data;
});


const onboardLanguages = createSlice({
    name: "onboardLanguages",
    initialState,
    reducers: {
        setLanguagesArray: (state, action) => {
            state.languagesArray = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(onboardLanguagesFunc.pending, (state) => {
            state.onboardLanguagesLoading = true;
        });
        builder.addCase(onboardLanguagesFunc.fulfilled, (state, action) => {
            state.onboardLanguagesLoading = false;
            state.onboardLanguagesData = action.payload.data?.onboardForeignLanguages;
            state.onboardLanguagesError = action.payload.errors;
        });
        builder.addCase(onboardLanguagesFunc.rejected, (state, action) => {
            state.onboardLanguagesLoading = false;
            state.onboardLanguagesError = action.error.message;
        });
    }
});

export const {
    setLanguagesArray
} = onboardLanguages.actions;


export default onboardLanguages.reducer;