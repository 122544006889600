import React from 'react'
import MuiThemeProvider from './MuiTheme/MuiThemeProvider'

const ThemeProvider = ({ children }) => {
    return (
        <MuiThemeProvider>
            {children}
        </MuiThemeProvider>
    )
}

export default ThemeProvider