import React from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({

    components: {
        // Name of the component
        // MuiTabs: {
        //     styleOverrides: {
        //         root: ({ ownerState }) => ({

        //             color: '#fff',
        //         }),
        //     },
        // },

        // MuiTab: {
        //     styleOverrides: {
        //         root: ({ ownerState }) =>  ({

        //                 color: ownerState.index === ownerState.value ? '#FF9737' : '#000',
        //                 textColor: ownerState.index === ownerState.value ? '#FF9737' : '#000',

        //             })

        //     },
        // }
    },

});


const MuiThemeProvider = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )

}

export default MuiThemeProvider