import { toast } from 'react-toastify';
import React, { useState } from 'react'
import { Box } from '@mui/material';

import { RouterSettings } from 'routers'
import { SignIn } from 'layouts/AuthLayout'
import { LottieLoadingAndSuccessfull } from 'components';

import AuthLogo from '../../EmployifAssets/employif_logo.png'
import AuthImage from '../../EmployifAssets/employif_login.jpg'


const EmployifSignin = () => {


    const [loading, setLoading] = useState(false)

    const handleSignin = ({ values }) => {

        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            toast.error("User could not find!", {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }, 1000)

    }


    return (
        <>

            <LottieLoadingAndSuccessfull loading={loading} />

            <SignIn
                appName="employif"
                authImage={AuthImage}
                handleSignin={handleSignin}
                bottomLink={RouterSettings.singleApp.auth.signup}
                forgetPasswordLink={RouterSettings.singleApp.auth.forgetPasswordRequest}
                logo={
                    <Box sx={{ width: { xs: '250px' }, textAlign: 'center', m: 'auto' }}>
                        <img src={AuthLogo} alt="" style={{ width: '100%' }} />
                    </Box>
                }
                iconStyle={{
                    width: '250px'
                }}
            />

        </>
    )
}


export default EmployifSignin