// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/';
const ROOTS_ONBOARDING = 'onboarding';

// ----------------------------------------------------------------------

// const intern = {

//     auth: {
//         homepage: path(ROOTS_AUTH, ""),
//         signup: path(ROOTS_AUTH, "signup"),
//         verifyEmail: path(ROOTS_AUTH, "verify-email"),
//         signupSuccessfull: path(ROOTS_AUTH, "signup-successfull"),
//         forgetPasswordRequest: path(ROOTS_AUTH, "forget-password"),
//         forgetPasswordSuccessfull: path(ROOTS_AUTH, "forget-password-successfull"),
//         resetPasswordRequest: path(ROOTS_AUTH, "reset"),
//         resetPasswordSuccessfull: path(ROOTS_AUTH, "reset-password-successfull"),
//         signin: path(ROOTS_AUTH, "signin"),
//     },
//     dashboard: {
//         homepage: path(ROOTS_DASHBOARD, ""),
//         internships: path(ROOTS_DASHBOARD, "internships"),
//         myapps: path(ROOTS_DASHBOARD, "myapps"),
//         notifications: path(ROOTS_DASHBOARD, "notifications"),
//         membership: path(ROOTS_DASHBOARD, "membership"),
//         company: {
//             root: path(ROOTS_DASHBOARD, "company"),
//             homepage: path(ROOTS_DASHBOARD, "company/:id"),
//             tasks: path(ROOTS_DASHBOARD, "tasks/:id"),
//             documents: path(ROOTS_DASHBOARD, "documents/:id"),
//             messages: path(ROOTS_DASHBOARD, "messages/:id"),
//         },
//         companies: path(ROOTS_DASHBOARD, "companies/:companyName"),
//         profile: path(ROOTS_DASHBOARD, "profile"),
//         coverLetters: path(ROOTS_DASHBOARD, "cover-letters"),
//         resumes: path(ROOTS_DASHBOARD, "resumes"),
//         settings: path(ROOTS_DASHBOARD, "settings"),
//     },
//     onboarding: {
//         homepage: path(ROOTS_ONBOARDING, ""),
//     },

// }
const company = {

    auth: {
        homepage: path(ROOTS_AUTH, ""),
        signup: path(ROOTS_AUTH, "signup"),
        verifyEmail: path(ROOTS_AUTH, "verify-email"),
        signupSuccessfull: path(ROOTS_AUTH, "signup-successfull"),
        forgetPasswordRequest: path(ROOTS_AUTH, "forget-password"),
        forgetPasswordSuccessfull: path(ROOTS_AUTH, "forget-password-successfull"),
        resetPasswordRequest: path(ROOTS_AUTH, "reset-password"),
        resetPasswordSuccessfull: path(ROOTS_AUTH, "reset-password-successfull"),
        signin: path(ROOTS_AUTH, "signin"),
    },
    dashboard: {
        homepage: path(ROOTS_DASHBOARD, ""),
        internships: path(ROOTS_DASHBOARD, "internships"),
        myapps: path(ROOTS_DASHBOARD, "myapps"),
        notifications: path(ROOTS_DASHBOARD, "notifications"),
        membership: path(ROOTS_DASHBOARD, "membership"),
        company: {
            root: path(ROOTS_DASHBOARD, "company"),
            homepage: path(ROOTS_DASHBOARD, "company/:id"),
            tasks: path(ROOTS_DASHBOARD, "tasks/:id"),
            documents: path(ROOTS_DASHBOARD, "documents/:id"),
            messages: path(ROOTS_DASHBOARD, "messages/:id"),
        },
        companies: path(ROOTS_DASHBOARD, "companies/:companyName"),
        profile: path(ROOTS_DASHBOARD, "profile"),
        coverLetters: path(ROOTS_DASHBOARD, "cover-letters"),
        resumes: path(ROOTS_DASHBOARD, "resumes"),
        settings: path(ROOTS_DASHBOARD, "settings"),
    },
    onboarding: {
        homepage: path(ROOTS_ONBOARDING, ""),
    },

}

const singleApp = {

    auth: {
        homepage: path(ROOTS_AUTH, ""),
        signup: path(ROOTS_AUTH, "signup"),
        verifyEmail: path(ROOTS_AUTH, "verify-email"),
        signupSuccessfull: path(ROOTS_AUTH, "signup-successfull"),
        forgetPasswordRequest: path(ROOTS_AUTH, "forget-password"),
        forgetPasswordSuccessfull: path(ROOTS_AUTH, "forget-password-successfull"),
        resetPasswordRequest: path(ROOTS_AUTH, "reset-password"),
        resetPasswordSuccessfull: path(ROOTS_AUTH, "reset-password-successfull"),
        signin: path(ROOTS_AUTH, "signin"),
    },
    dashboard: {
        homepage: path(ROOTS_DASHBOARD, ""),
        internships: path(ROOTS_DASHBOARD, "internships"),
        myapps: path(ROOTS_DASHBOARD, "myapps"),
        notifications: path(ROOTS_DASHBOARD, "notifications"),
        membership: path(ROOTS_DASHBOARD, "membership"),
        company: {
            root: path(ROOTS_DASHBOARD, "company"),
            homepage: path(ROOTS_DASHBOARD, "company/:id"),
            tasks: path(ROOTS_DASHBOARD, "tasks/:id"),
            documents: path(ROOTS_DASHBOARD, "documents/:id"),
            messages: path(ROOTS_DASHBOARD, "messages/:id"),
        },
        companies: path(ROOTS_DASHBOARD, "companies/:companyName"),
        profile: path(ROOTS_DASHBOARD, "profile"),
        coverLetters: path(ROOTS_DASHBOARD, "cover-letters"),
        resumes: path(ROOTS_DASHBOARD, "resumes"),
        settings: path(ROOTS_DASHBOARD, "settings"),
    },
    onboarding: {
        homepage: path(ROOTS_ONBOARDING, ""),
    },

}

const RouterSettings = {
    // intern,
    company,
    singleApp
}

export default RouterSettings