import React from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid } from '@mui/material'

import {
    WhiteButtonOne,
    BlackButtonOne,
    DatePickerComponent,
    BasicInputComponent,
    SwipeableAutoCompleteComponent,
} from 'components';
import { TextButtonOne } from 'components';
import { useSelector } from 'react-redux';



const ValidationSchema = yup.object().shape({
    name: yup.string()
        .required('Required!'),
    department: yup.object().nullable()
        .required('Required!'),
    startDate: yup.date("You must select a date")
        .required('Required!'),
    finishDate: yup.date("You must select a date")
        .required('Required!'),
})

const HighSchoolFormSection = ({ addOrUpdateHighSchool, setShowForm, highSchool }) => {

    const { highSchoolDepartmentsData } = useSelector(state => state?.highSchoolDepartments)
    // console.log(highSchool)

    const {
        errors,
        values,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        isValid
    } = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: highSchool?.name ?? "",
            department: highSchool?.department ?? null,
            startDate: highSchool?.startDate ?? "",
            finishDate: highSchool?.finishDate ?? "",
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            addOrUpdateHighSchool({ values })
        }

    })
    // ************************************* formik end *********************


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
            }}>
            <Grid container spacing={3}>

                <Grid item xs={12} md={6} >
                    <BasicInputComponent
                        name="name"
                        label="High School"
                        placeholder="Enter your high school"
                        error={touched?.name && errors?.name}
                        value={values?.name}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <SwipeableAutoCompleteComponent
                        name="department"
                        label="Department"
                        placeholder="Enter your department"
                        error={touched?.department && errors?.department}
                        values={values?.department}
                        list={highSchoolDepartmentsData ?? [{ _id: "1", name: "department" }]}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <DatePickerComponent
                        name="startDate"
                        label="Start Date"
                        placeholder="Select data"
                        error={touched?.startDate && errors?.startDate}
                        value={values?.startDate}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}

                        minDate="1990-1-1"
                        maxDate={new Date().getTime()}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <DatePickerComponent
                        name="finishDate"
                        label="Finish Date"
                        placeholder="Select data"
                        error={touched?.finishDate && errors?.finishDate}
                        value={values?.finishDate}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}

                        minDate={values?.startDate ? values.startDate : "1990-1-1"}
                        maxDate="2040-1-1"
                    />
                </Grid>

            </Grid>

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: { xs: '10px' },
                }}
            >

                <TextButtonOne
                    text="Cancel"
                    onClick={() => setShowForm(false)}
                    isButtonAktive={true}
                />

                <BlackButtonOne
                    text={`${highSchool ? "Update" : "Add"} High School`}
                    handleClick={handleSubmit}
                    isButtonAktive={isValid}
                    styles={{ maxWidth: '160px' }}
                />

            </Box>

        </Box>
    )
}

export default HighSchoolFormSection