import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";


const initialState = {
    positionsData: null,
    positionsLoading: false,
    positionsError: null,
};


export const positionsFunc = createAsyncThunk("positions/positionsFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Data {
            parameters {
              positions {
                data {
                  _id
                  name
                }
              }
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const positions = createSlice({
    name: "positions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(positionsFunc.pending, (state) => {
            state.positionsLoading = true;
        });
        builder.addCase(positionsFunc.fulfilled, (state, action) => {
            state.positionsLoading = false;
            state.positionsData = action.payload.data?.parameters.positions?.data;
        });
        builder.addCase(positionsFunc.rejected, (state, action) => {
            state.positionsLoading = false;
            state.positionsError = action.error.message;
        });
    }
});

export default positions.reducer;