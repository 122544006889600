import React from 'react';

import {
  GoToHome,
  UpperTextContainer,
  BottomLink,
  SocialLoginButtons,
  AuthLayout,
  FormLayout,
} from 'layouts/AuthLayout';
import SignInForm from './_SignInForm';
import { InternifLogoOnly } from 'assets';



const SignIn = ({ authImage, bottomLink, handleSignin, forgetPasswordLink, logo = null, iconStyle }) => {

  return (
    <AuthLayout authImage={authImage} >

      <GoToHome />

      <UpperTextContainer
        icon={logo ?? <InternifLogoOnly />}
        iconStyle={{ display: { xs: 'none', md: 'block' }, ...iconStyle }}
        title="Sign In Now"
        description={logo ? "Let’s start to discover next step for your future" : "Let’s start to discover next step for your company"}
      // description="Let’s start to discover next step for your company"
      />

      <FormLayout>

        <SocialLoginButtons text="or sign in with e-mail" buttonText="Sign In" />
        <SignInForm handleSignin={handleSignin} forgetPasswordLink={forgetPasswordLink} logo={logo} />

      </FormLayout>

      <BottomLink
        // text="Don’t have an account?"
        // linkText="Sign Up"
        text={logo ? "EmployIF is a brand of international facilitator." : "Don’t have an account?"}
        linkText={logo ? "" : "Sign Up"}
        link={bottomLink}
      />

    </AuthLayout>
  );
};

export default SignIn;
