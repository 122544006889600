import React from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid, Typography } from '@mui/material'

import { BoxContainerLayout } from 'layouts';
import { DashboardHeaderTextOne, BasicInputComponent, BlackButtonOne } from 'components';
import { SettingIcon } from 'assets';


const validationSchema = yup.object({
    currentPassword: yup.string()
        //     .matches(REGEXPATTERN.atLeastOnelowerCases, "Must contain lower case!")
        //     .matches(REGEXPATTERN.atLeastOneupperCases, "Must contain upper case!")
        //     .matches(REGEXPATTERN.atLeastOnenumbers, "Must contain number!") // /(?=.*\d)/  bu da olur
        //     .matches(REGEXPATTERN.atLeastOnepecialCharacters, "Must contain special character!")
        .min(8, "Password must be at least 8 characters!")
        .required('Required!'),
    newPassword: yup.string()
        //     .matches(REGEXPATTERN.atLeastOnelowerCases, "Must contain lower case!")
        //     .matches(REGEXPATTERN.atLeastOneupperCases, "Must contain upper case!")
        //     .matches(REGEXPATTERN.atLeastOnenumbers, "Must contain number!") // /(?=.*\d)/  bu da olur
        //     .matches(REGEXPATTERN.atLeastOnepecialCharacters, "Must contain special character!")
        .min(8, "Password must be at least 8 characters!")
        .oneOf([yup.ref('passwordConfirm'), null], 'Passwords must match')
        .required('Required!'),
    passwordConfirm: yup.string()
        //     .matches(REGEXPATTERN.atLeastOnelowerCases, "Must contain lower case!")
        //     .matches(REGEXPATTERN.atLeastOneupperCases, "Must contain upper case!")
        //     .matches(REGEXPATTERN.atLeastOnenumbers, "Must contain number!") // /(?=.*\d)/  bu da olur
        //     .matches(REGEXPATTERN.atLeastOnepecialCharacters, "Must contain special character!")
        .min(8, "Password must be at least 8 characters!")
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
        .required('Required!'),
})


const SettingsSection = ({ handleChangePassword }) => {

    const {
        errors,
        values,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
    } = useFormik({

        initialValues: {
            currentPassword: '',
            newPassword: '',
            passwordConfirm: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            handleChangePassword({ values })
            // const config = {
            //   method: 'post',
            //   url: process.env.REACT_APP_SERVER_BASE_URL_AUTH + "resetpassword",
            //   headers: {
            //     // 'Authorization': `Bearer ${accessToken}`,
            //     'Content-Type': 'application/json'
            //   },
            //   data: JSON.stringify({
            //     password: values?.newPassword,
            //     oldPassword: values?.currentPassword,
            //   })
            // }

            // handleMutation({
            //   myconfig: config,
            //   onSuccess: (data) => {
            //     console.log('successfull', data)
            //   }
            //   ,
            //   onError: (err) => {
            //     console.log('error', err)

            //     if (err?.data?.message === "PasswordMustBeDifferentFromPrevious") {
            //       toast(
            //         "Password must be different from previous!",
            //         {
            //           style: {
            //             fontFamily: 'Causten-Semibold',
            //             backgroundColor: COLORS.error,
            //             color: COLORS.errorReverse,
            //           }
            //         }
            //       )
            //     }
            //   }
            // })

        }

    })

    const isButtonAktive = (
        values?.currentPassword !== "" &&
        values?.newPassword !== "" &&
        values?.passwordConfirm !== "" &&

        !errors?.currentPassword &&
        !errors?.newPassword &&
        !errors?.passwordConfirm
    )

    return (
        <>
            <DashboardHeaderTextOne
                title="Settings"
                description="Last updated at 12/02, 12:30pm"
            />

            <BoxContainerLayout>

                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                        mb: { xs: '30px', sm: '40px' }
                    }}
                >

                    <SettingIcon />

                    <Box>

                        <Typography
                            sx={{
                                fontFamily: 'Causten-SemiBold',
                                fontSize: { xs: '16px', sm: '24px' },
                                lineHeight: { xs: '16px', sm: '28px' },
                                color: '#0E1436',
                                mb: '4px'
                            }}
                        >
                            Password
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Causten-Regular',
                                fontSize: { xs: '12px', sm: '16px' },
                                lineHeight: { xs: '16px', sm: '19px' },
                                color: '#96A1BA',
                            }}
                        >
                            You can change your password in here.
                        </Typography>

                    </Box>

                </Box>

                <Grid container spacing={2} >

                    <Grid item xs={12} md={6}>
                        <BasicInputComponent
                            name="currentPassword"
                            type="password"
                            label="Current Password"
                            placeholder="Current Password"


                            error={touched?.currentPassword && errors?.currentPassword}
                            value={values?.currentPassword}
                            handleBlur={handleBlur}
                            handleChange={handleChange}

                            // setFieldValue={setFieldValue}
                            showPasswordIcon
                        />
                    </Grid>

                    <Grid item xs={12} md={6} />

                    <Grid item xs={12} md={6}>
                        <BasicInputComponent
                            name="newPassword"
                            type="password"
                            label="New Password"
                            placeholder="New Password"

                            error={touched.newPassword && errors.newPassword}
                            value={values.newPassword}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            showPasswordIcon
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <BasicInputComponent
                            name="passwordConfirm"
                            type="password"
                            label="New Password Again"
                            placeholder="New Password Again"

                            error={touched.passwordConfirm && errors.passwordConfirm}
                            value={values.passwordConfirm}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            showPasswordIcon
                        />
                    </Grid>

                </Grid>

                <BlackButtonOne
                    text='Change Password'
                    isButtonAktive={isButtonAktive}
                    handleClick={handleSubmit}
                    styles={{
                        maxWidth: { xs: '100%', sm: '250px' },
                        my: { xs: '40px' }
                    }}
                />

            </BoxContainerLayout>
        </>
    )
}

export default SettingsSection