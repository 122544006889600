import React from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid } from '@mui/material'

import {
    TextButtonOne,
    BlackButtonOne,
    BasicInputComponent,
    // AutoCompleteCityComponent,
    AutoCompleteCountryComponent,
    SwipeableAutoCompleteComponent,
} from 'components';
import { useOnboardingContext } from 'layouts/OnboardingLayout/OnboardingContext/OnboardingContext';
import { OnboardingLayout } from 'layouts/OnboardingLayout';



const ValidationSchema = yup.object().shape({
    universityName: yup.string()
        .required("Required!"),
    mainCampus: yup.object().nullable()
        .required("Required!"),
    country: yup.object().nullable()
        .required("Required!"),
})


const UniversityInformationForm1 = () => {

    const { setOnboardingFormNumber, setCurrentPage, onboardingPageList } = useOnboardingContext()

    // ************************************* formik  start *********************
    const {
        errors,
        values,
        touched,
        handleBlur,
        handleChange,
        setFieldValue,
        // handleSubmit,
    } = useFormik({

        initialValues: {
            universityName: "",
            country: null,
            mainCampus: null,
        },

        validationSchema: ValidationSchema,
        onSubmit: (e) => {
            e.preventDefault();
        }

    })
    // ************************************* formik end *********************


    const handleNextStep = () => {
        setCurrentPage(onboardingPageList[1]?.pages[2])
        setOnboardingFormNumber(prevState => prevState + 1)
    }

    const handleSkipStep = () => {
        setCurrentPage(onboardingPageList[1]?.pages[2])
        setOnboardingFormNumber(prevState => prevState + 1)
    }

    const handlePreviousStep = () => {
        setCurrentPage(onboardingPageList[0]?.pages[0])
        setOnboardingFormNumber(prevState => prevState - 2)
    }


    return (
        <OnboardingLayout>

            <Grid container spacing={3} >

                <Grid item xs={12} lg={6}>
                    <BasicInputComponent
                        name="universityName"
                        label="University Name"
                        placeholder="Enter university name"

                        value={values?.universityName}
                        error={touched.universityName && errors.universityName}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Box />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <AutoCompleteCountryComponent
                        name="country"
                        label="Country (Headquarter)"
                        error={touched.country && errors.country}
                        values={values?.country}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <SwipeableAutoCompleteComponent
                        name="mainCampus"
                        label="Main Campus"
                        placeholder="Select main campus"
                        list={[{ _id: "1", name: "Main Campus" }]}

                        error={touched.mainCampus && errors.mainCampus}
                        values={values?.mainCampus}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: 3 },
                    justifyContent: 'space-between',
                    mt: '20px',
                }}
            >

                <TextButtonOne onClick={handlePreviousStep} />

                {
                    true ? (
                        <BlackButtonOne
                            text="Next Step"
                            handleClick={handleNextStep}
                            styles={{ maxWidth: '160px' }}
                            isButtonAktive={true}
                        />
                    ) : (
                        <BlackButtonOne
                            text="Skip Step"
                            handleClick={handleSkipStep}
                            styles={{ maxWidth: '160px' }}
                            isButtonAktive={true}
                        />
                    )
                }

            </Box>

        </OnboardingLayout>
    )
}

export default UniversityInformationForm1