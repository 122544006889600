import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    internOnboardingStatusData: null,
    internOnboardingStatusLoading: false,
    internOnboardingStatusError: null,
};


export const internOnboardingStatusFunc = createAsyncThunk("internOnboardingStatus/internOnboardingStatusFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Intern {
            intern {
              internOnboardingStatus
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const internOnboardingStatus = createSlice({
    name: "internOnboardingStatus",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(internOnboardingStatusFunc.pending, (state) => {
            state.internOnboardingStatusLoading = true;
        });
        builder.addCase(internOnboardingStatusFunc.fulfilled, (state, action) => {
            state.internOnboardingStatusLoading = false;
            state.internOnboardingStatusData = action?.payload?.data?.intern?.internOnboardingStatus;
        });
        builder.addCase(internOnboardingStatusFunc.rejected, (state, action) => {
            state.internOnboardingStatusLoading = false;
            state.internOnboardingStatusError = action.error.message;
        });
    }
});

export default internOnboardingStatus.reducer;