import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';

// import VideoFinishedIcon from '../../../../Assets/Icons/onboarding/video-finished-icon.png';


const useStyles = makeStyles({
    animatedCount: {
        animation: '$fade 1s 1s infinite',
    },
    '@keyframes fade': {
        '0%': { opacity: '0' },
        '100%': { opacity: '1' },
    },
})

const countDownStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: "blur(5px)",
    p: 2,
    color: '#fff',

    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}


const VideoScreens = ({ recordWebcam, preparationTime, second, recordAgain, recordCount }) => {

    const styles = useStyles()

    const [isBlur, setIsBlur] = useState(true)

    // const hideBlurEffect = () => {
    //     setIsBlur((prevState) => !prevState)
    // }

    return (
        <Box
            sx={{
                position: 'absolute',
                top: '0px',
                width: '100%',
                height: '100%',
            }}
        >
            {
                (recordWebcam.status === "INIT" || recordWebcam.status === "OPEN" || recordWebcam.status === "CLOSED") && ( // before record
                    <Box
                        sx={{
                            // position: 'absolute',
                            // bottom: '0px',
                            width: '100%',
                            height: '100%',

                            backgroundColor: isBlur ? 'rgba(255, 255, 255, 0.5)' : 'rgba(255, 255, 255, 0)',
                            backdropFilter: isBlur ? "blur(5px)" : "blur(0px)",
                            p: 5,
                            display: 'flex',
                            alignItems: 'end',
                            justifyContent: 'center',
                            gap: 2,
                        }}
                    >

                        {
                            (preparationTime === 0) ? ( // before record screen
                                <>
                                    {/* <Button
                                    onClick={hideBlurEffect}
                                    sx={{
                                        backgroundColor: 'rgba(227, 231, 242, 0.001)',
                                        // backdropFilter: "blur(1px)",
                                        borderRadius: '100px',
                                        textTransform: 'none',
                                        color: '#fff',

                                        py: '16px',
                                        px: '16px',
                                        boxShadow: 'none',
                                        border: 'none',
                                        minWidth: '200px',

                                        '& *': {
                                            backgroundColor: 'rgba(255, 255, 255, 0)',
                                            backdropFilter: "blur(8px)",
                                        },
                                        ':hover': {
                                            backgroundColor: 'rgba(227, 231, 242, 0.3)',
                                        },
                                    }}
                                >
                                    {isBlur ? 'Open' : 'Close'}
                                </Button> */}
                                </>
                            ) : ( // before record getting ready screen
                                <Typography
                                    sx={countDownStyle}
                                    className={styles.animatedCount}
                                >
                                    {preparationTime}
                                </Typography>
                            )

                        }

                    </Box>
                ) ||

                (recordWebcam.status === "RECORDING") && ( // when recording countdown
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '0px',
                            width: '100%',
                            height: '432px',


                            p: 5,
                            display: 'flex',
                            alignItems: 'start',
                            justifyContent: 'end',
                            gap: 2,

                            zIndex: 1000,
                        }}
                    >

                        <Typography
                            sx={countDownStyle}
                            className={styles.animatedCount}
                        >
                            {second}
                        </Typography>

                    </Box>
                ) ||

                (recordWebcam.status === "PREVIEW") && ( // after record video screen text
                    <Box
                        sx={{
                            width: '100%',
                            height: '80%',
                            //    backgroundColor: 'red',

                            position: 'absolute',
                            top: 0,
                            left: 0,
                            zIndex: 100,

                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}
                    >
                        <Box
                            sx={{

                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                backdropFilter: "blur(5px)",

                                // position: 'absolute',
                                // bottom: '12.5%',
                                // left: '12.5%',
                                // width: '100%',
                                mx: "auto",
                                p: { xs: '8px', sm: '14px', lg: '18px' },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                // gap: "10px",
                                borderRadius: '8px',

                            }}
                        >

                            <Box
                                sx={{

                                }}
                            >
                                {/* <img src={VideoFinishedIcon} alt="" /> */}
                            </Box>

                            <Typography
                                sx={{
                                    pt: { xs: '8px', sm: '14px', lg: '18px' },
                                    pb: { xs: '8px', sm: '14px', lg: '25px' },
                                    textAlign: 'center',
                                    color: '#fff',

                                    fontFamily: 'Causten-Regular',
                                    fontSize: { xs: '12px', sm: '14px', lg: '18px' }
                                }}
                            >
                                Your answer has recorded succesfully. <br />
                                If you want to record again you have {recordCount} rights left.
                            </Typography>
                            <Button
                                onClick={recordAgain}
                                sx={{
                                    backgroundColor: '#EB3641',
                                    width: { xs: '108px', sm: '124px', lg: '158px' },
                                    height: '48px',
                                    borderRadius: '80px',
                                    textTransform: 'none',
                                    color: '#FFF',

                                    fontFamily: 'Causten-Regular',
                                    fontSize: { xs: '12px', sm: '14px', lg: '18px' },

                                    ':hover': {
                                        backgroundColor: 'rgba(235, 54, 66, 0.9)',
                                    }
                                }}
                            >
                                Record Again
                            </Button>

                        </Box>
                    </Box>
                )

            }
        </Box>
    )
}

export default VideoScreens