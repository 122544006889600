import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
  internPersonalInformationData: null,
  internPersonalInformationLoading: false,
  internPersonalInformationError: null,
};


export const internPersonalInformationFunc = createAsyncThunk("internPersonalInformation/internPersonalInformationFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query PersonalInformation {
      intern {
        personalInformation {
          fullName
          birthDate
          country {
            _id
            name
            code
          }
          city {
            _id
            name
          }
          description
        }
      }
    }`
  });

  var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

  const response = axios(config)
    .then((res) => {
      const data = res.data;
      // console.log("lklkhl", data)

      return data;
    }).catch((err) => {
      // console.log("lklkhl", err)

      return err;
    });

  const data = await response;
  // console.log("lklkhl", data)
  return data;
});


const internPersonalInformation = createSlice({
  name: "internPersonalInformation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(internPersonalInformationFunc.pending, (state) => {
      state.internPersonalInformationLoading = true;
    });
    builder.addCase(internPersonalInformationFunc.fulfilled, (state, action) => {
      // console.log("lklkhl", action.payload)
      state.internPersonalInformationLoading = false;
      state.internPersonalInformationData = action.payload.data?.intern?.personalInformation;
      state.internPersonalInformationError = action.payload.errors;
    });
    builder.addCase(internPersonalInformationFunc.rejected, (state, action) => {
      console.log("khkj", action.error)
      state.internPersonalInformationLoading = false;
      state.internPersonalInformationError = action.error;
    });
  }
});

export default internPersonalInformation.reducer;