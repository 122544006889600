import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

//AUTH
import signin from "./auth/signin";
import signup from "./auth/signup";
import emailConfirmation from "./auth/emailConfirmation";
import resendConfirmation from "./auth/resendConfirmation";
import forgotPassword from "./auth/forgotPassword";
import signupGoogle from "./auth/signupGoogle";
import signinGoogle from "./auth/signinGoogle";

// PARAMETERS
import countries from "./parameters/countries";
import cities from "./parameters/cities";
import highSchoolDepartments from "./parameters/highSchoolDepartments";
import universityDepartments from "./parameters/universityDepartments";
import universities from "./parameters/universities";
import languages from "./parameters/languages";
import languageLevels from "./parameters/languageLevels";
import positions from "./parameters/positions";
import skills from "./parameters/skills";
import usageReasons from "./parameters/usageReasons";

//ONBOARDING
import personalInformation from "./onboarding/personalInformation";
import onboardEducation from "./onboarding/onboardEducation";
import onboardLanguages from "./onboarding/onboardLanguages";
import onboardExperiences from "./onboarding/onboardExperiences";
import onboardSkills from "./onboarding/onboardSkills";
import onboardCertificates from "./onboarding/onboardCertificates";

//INTERN
import internEducations from "./intern/internEducations";
import internOnboardingStatus from "./intern/internOnboardingStatus";
import internPersonalInformation from "./intern/internPersonalInformation";


const store = configureStore({
    reducer: {
        signin,
        signup,
        emailConfirmation,
        resendConfirmation,
        forgotPassword,
        signupGoogle,
        signinGoogle,

        personalInformation,
        highSchoolDepartments,
        universities,
        universityDepartments,
        internEducations,
        internOnboardingStatus,
        internPersonalInformation,

        cities,
        countries,
        onboardEducation,
        languages,
        languageLevels,
        onboardLanguages,
        onboardExperiences,
        positions,
        skills,
        onboardSkills,
        onboardCertificates,
        usageReasons,
    },
    middleware: [thunk]
});


export default store;