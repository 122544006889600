import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";
import { useSelector } from "react-redux";

const initialState = {
    citiesData: [],
    citiesLoading: false,
    citiesError: null,
};


export const citiesFunc = createAsyncThunk("cities/citiesFunc", async (values) => {


    // console.log(values);
    var data1 = JSON.stringify({
        query: `query Cities($countryId: ID!) {
            cities(countryId: $countryId) {
              data {
                _id
                name
              }
            }
          }`,
        variables: {
            "countryId": values.countryId
        }
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const cities = createSlice({
    name: "cities",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(citiesFunc.pending, (state) => {
            state.citiesLoading = true;
        });
        builder.addCase(citiesFunc.fulfilled, (state, action) => {
            // console.log(action.payload)
            state.citiesLoading = false;
            state.citiesData = action.payload.data?.cities?.data;
            state.citiesError = action.payload.errors;
        });
        builder.addCase(citiesFunc.rejected, (state, action) => {
            console.log(action.error)
            state.citiesLoading = false;
            state.citiesError = action.error.message;
        });
    }
});

export default cities.reducer;