import React from 'react'
import { EmployifAppRouter } from 'routers'

const EmployifApp = () => {
    return (
        <div className='employif_app app_container'>
            <EmployifAppRouter />
        </div>
    )
}

export default EmployifApp