import EmployifAuthPages from "./EmployifAuthPages/EmployifAuthPages";

import EmployifSignin from "./EmployifAuthPages/8-EmployifSignin/EmployifSignin";
import EmployifForgetPasswordRequest from "./EmployifAuthPages/4-EmployifForgetPasswordRequest/EmployifForgetPasswordRequest";



const EmployifPages = {
    EmployifAuthPages,

    EmployifSignin,
    EmployifForgetPasswordRequest,
}

export default EmployifPages