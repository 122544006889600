import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    forgotPasswordData: [],
    forgotPasswordLoading: false,
    forgotPasswordError: null,
};



export const forgotPasswordFunc = createAsyncThunk("forgotPassword/forgotPasswordFunc", async (email) => {


    var data1 = JSON.stringify({
        "email": email,
    });

    var config = initialConfig('https://api.internif.com/auth/auth/requestresetpassword', data1);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const forgotPassword = createSlice({
    name: "forgotPassword",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(forgotPasswordFunc.pending, (state) => {
            state.forgotPasswordLoading = true;
        });
        builder.addCase(forgotPasswordFunc.fulfilled, (state, action) => {
            state.forgotPasswordLoading = false;
            state.forgotPasswordData = action.payload;
        });
        builder.addCase(forgotPasswordFunc.rejected, (state, action) => {
            state.forgotPasswordLoading = false;
            state.forgotPasswordError = action.error.message;
        });
    }
});

export default forgotPassword.reducer;