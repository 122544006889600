import React from 'react'
import { Link, Typography, } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'

const styles = {
    text: {
        fontFamily: 'Causten-Medium',
        color: '#70798D',
        fontSize: { xs: '12px' },
        lineHeight: { xs: '20px' },
        mt: 2,

        position: { md: 'absolute' },
        bottom: { xs: '50px', md: '10px', lg: '30px' }
    },
    text_link: {
        fontFamily: 'Causten-Bold',
        color: '#3BA1EB',
        textDecoration: 'none',
    },
}


const BottomLink = ({ text, link, linkText, textStyle, linkStyle, secondText }) => {
    return (
        <Typography sx={{ ...styles.text, ...textStyle }} >
            {text}
            <Link component={RouterLink} to={link} style={{ ...styles.text_link, ...linkStyle }}> {linkText} </Link>
            {secondText}
        </Typography>
    )
}

export default BottomLink