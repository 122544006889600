import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box } from '@mui/material';

import { BlackButtonOne, BasicInputComponent } from 'components';



const ValidationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address!')
        .required('Required!'),
})


const ForgetPasswordRequestForm = ({ handleForgetPasswordRequest }) => {

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        values,
        touched
    } = useFormik({
        initialValues: { email: '' },
        validationSchema: ValidationSchema,
        onSubmit: () => {
            handleForgetPasswordRequest({ values })
        }
    })

    return (
        <form onSubmit={handleSubmit} >

            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    gap: { xs: 2, sm: 3 },
                }}
            >

                <BasicInputComponent
                    name="email"
                    type="email"
                    label="E-mail Address"
                    placeholder="Enter your e-mail address"

                    value={values?.email}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={touched.email && errors.email}
                />

                <BlackButtonOne
                    text="Send Reset Link"
                    handleClick={handleSubmit}
                    isButtonAktive={(Object.keys(touched).length > 0) && !(Object.keys(errors).length > 0)}
                />

            </Box>
        </form>
    );
};

export default ForgetPasswordRequestForm;
