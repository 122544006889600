import React from 'react'
import { Box } from '@mui/material'
import { keyframes } from '@mui/system';


// const Loading = () => {
//     return (
//         <Box
//             sx={{
//                 width: '100%',
//                 height: '100vh',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 position: 'relative',
//                 MozBoxShadow: { xs: '0px 0px 50px 40px #ec6f1c', },
//                 WebKitBoxShadow: { xs: '0px 0px 50px 40px #ec6f1c', },
//                 boxShadow: { xs: '0px 0px 50px 40px #ec6f1c', },

//                 position: 'absolute',
//                 top: '0px',
//                 zIndex: 1000,

//                 backgroundColor: 'rgba(255, 255, 255, 0.3)',
//                 backdropFilter: "blur(8px)"
//             }}
//         >
//             <Box className="loading_outher">
//             </Box>
//             <Box className="loading_inner">
//             </Box>
//             {/* <img src={LogoImage} alt="logo" className="loading_img" style={{ width: '50px' }} /> */}
//         </Box>
//     )
// }


const spin = keyframes`
  0% { width: 0px }
  100% { width: 100% },
`;


const UpperLoadingBar = ({ loadingTime }) => {

    const newLoadingTime = loadingTime ? loadingTime : "1000ms"

    return (
        <Box
            sx={{
                width: '100%',
                position: 'absolute',
                top: '0px',
                zIndex: 10000,
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                backdropFilter: "blur(8px)",
            }}
        >

            <Box
                sx={{
                    p: { xs: '1px' },
                    backgroundColor: '#191922',
                    animation: `${spin} ${newLoadingTime}ms ease-out`
                }}
            />

        </Box>
    )
}
export default UpperLoadingBar