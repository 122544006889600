import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    signupData: [],
    signupLoading: false,
    signupError: null,
};


export const signupFunc = createAsyncThunk("signup/signupFunc", async ({ values, userRole }) => {

    var data1 = JSON.stringify({
        "email": values.email,
        "password": values.password,
        "role": userRole
    });

    var config = initialConfig('https://api.internif.com/auth/auth/register', data1);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const signup = createSlice({
    name: "signup",
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(signupFunc.pending, (state) => {
                state.signupLoading = true;
            });
            builder.addCase(signupFunc.fulfilled, (state, action) => {
                state.signupLoading = false;

                console.log(action.payload)
                state.signupData = action.payload;
            });
            builder.addCase(signupFunc.rejected, (state, action) => {
                state.signupLoading = false;
                state.signupError = action.response
            });
        }
});

export default signup.reducer;