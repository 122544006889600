import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    signupGoogleData: [],
    signupGoogleLoading: false,
    signupGoogleError: null,
};



export const signupGoogleFunc = createAsyncThunk("signupGoogle/signupGoogleFunc", async (values) => {


    var data1 = JSON.stringify({
        "token": values.token,
        "role": values.userRole
    });

    var config = initialConfig('https://api.internif.com/auth/oauth/google/register', data1);

    const response = axios(config).then((res) => {
        console.log('sdfasdfadf');
        const data = res.data;
        return data;
    }).catch((res) => {
        console.log('aaaa');
        return res;
    });

    const data = await response;
    return data;
});


const signupGoogle = createSlice({
    name: "signupGoogle",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(signupGoogleFunc.pending, (state) => {
            state.signupGoogleLoading = true;
        });
        builder.addCase(signupGoogleFunc.fulfilled, (state, action) => {
            state.signupGoogleLoading = false;
            state.signupGoogleData = action.payload;
        });
        builder.addCase(signupGoogleFunc.rejected, (state, action) => {
            console.log('zzz');
            state.signupGoogleLoading = false;
            state.signupGoogleError = action.error.message;
        });
    }
});

export default signupGoogle.reducer;