import React, {
    useState
} from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Backdrop, Box, Checkbox, Link, Typography } from '@mui/material';

// #### custom imports
import { regexPattern } from 'constatns'
import { BasicInputComponent, BlackButtonOne } from 'components';
import UserAgreement from './_UserAgreementComponent';


const ValidationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address!')
        .required('Required!'),
    password: Yup.string()
        .matches(regexPattern.atLeastOnelowerCases, "Must contain lower case!")
        .matches(regexPattern.atLeastOneupperCases, "Must contain upper case!")
        .matches(regexPattern.atLeastOnenumbers, "Must contain number!")
        .matches(regexPattern.atLeastOnepecialCharacters, "Must contain special character!")
        .min(8, "Password must be at least 8 characters!")
        .required('Required!'),
    acceptTerms: Yup.bool()
        .required('Required!')
        .oneOf([true], 'Accept Terms & Conditions is required')
})


const SignUpForm = ({ handleSignup }) => {

    const [backdrop, setBackdrop] = useState(false)

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        values,
        touched,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
            acceptTerms: false,
        },
        validationSchema: ValidationSchema,
        onSubmit: () => {
            handleSignup({ values })
        }
    })


    return (
        <form onSubmit={handleSubmit} >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    gap: '24px',
                }}
            >

                <BasicInputComponent
                    name="email"
                    type="email"
                    label="E-mail Address"
                    placeholder="Enter your e-mail address"

                    error={touched.email && errors.email}
                    value={values?.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                />

                <BasicInputComponent
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="Enter a strong password"

                    error={touched?.password && errors?.password}
                    value={values?.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}

                    showPasswordIcon
                />


                <Box sx={{ position: 'relative', }}>

                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                        }}
                    >
                        <Checkbox
                            inputProps={{
                                'aria-label': 'Checkbox demo',
                            }}
                            name="acceptTerms"
                            id="acceptTerms"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            sx={{
                                p: 0,
                                color: "#70798D",
                                '&.Mui-checked': {
                                    color: "#191922",
                                },
                            }}
                        />

                        <Typography sx={{
                            color: touched.acceptTerms && errors.acceptTerms ? 'red' : (values?.acceptTerms ? '#000' : '#70798D'),
                            fontFamily: 'Causten-Medium',
                            fontSize: { xs: '12px' },
                            lineHeight: { xs: '20px' },
                        }}>
                            I’ve read and accept the
                            <Link
                                onClick={() => setBackdrop(true)}
                                style={{
                                    fontFamily: 'Causten-Bold',
                                    color: '#3BA1EB',
                                    textDecoration: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                &nbsp; User Agreement &nbsp;
                            </Link>
                            of InternIF.
                        </Typography>
                    </Box>

                    <Backdrop
                        open={backdrop}
                        onClickCapture={() => setBackdrop(false)}
                        sx={{
                            color: '#fff',
                            zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                    >
                        <UserAgreement setBackdrop={setBackdrop} />
                    </Backdrop>

                    {
                        touched.acceptTerms &&
                        (<Typography
                            sx={{
                                color: 'red',
                                fontFamily: 'Causten-Medium',
                                fontSize: { xs: '12px' },
                                lineHeight: { xs: '20px' },
                                position: 'absolute',
                                bottom: '-15px',
                                left: '30px',
                            }}
                        >
                            {errors.acceptTerms}
                        </Typography>)
                    }

                </Box>

                <BlackButtonOne
                    text="Sign Up Free"
                    handleClick={handleSubmit}
                    isButtonAktive={(Object.keys(touched).length > 0) && !((Object.keys(errors).length > 0))}
                />

            </Box>
        </form>
    );
};


export default SignUpForm;
