import React from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box } from '@mui/material';

import {
    TextButtonOne,
    BlackButtonOne,
    ReactQuillEditor,
    BasicInputComponent,
} from 'components';
import { useOnboardingContext } from 'layouts/OnboardingLayout/OnboardingContext/OnboardingContext';
import { OnboardingLayout } from 'layouts/OnboardingLayout';



const ValidationSchema = yup.object().shape({
    about: yup.string().required("Required!"),
    description: yup.string()
        .test('Only Empty?', 'Required!', (value) => {
            if (value === "") { }
            const isValid = value?.split('<p><br></p>').join('').length !== 0;
            return isValid;
        })
        .required("Required!"),
})


const CompanyInformationDescriptions = () => {

    const { setOnboardingFormNumber, setCurrentPage, onboardingPageList } = useOnboardingContext()

    // ************************************* formik  start *********************
    const {
        errors,
        values,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldTouched
        // handleSubmit,
    } = useFormik({
        initialValues: {
            about: '',
            description: '',
        },
        validationSchema: ValidationSchema,
        onSubmit: (e) => {
            e.preventDefault();
        }
    })
    // ************************************* formik end *********************


    const isButtonAktive = true

    const handleNextStep = () => {
        setCurrentPage(onboardingPageList[2]?.pages[0])
        setOnboardingFormNumber(prevState => prevState + 1)
    }

    const handleSkipStep = () => {
        setCurrentPage(onboardingPageList[2]?.pages[0])
        setOnboardingFormNumber(prevState => prevState + 1)
    }

    const handlePreviousStep = () => {
        setCurrentPage(onboardingPageList[1]?.pages[2])
        setOnboardingFormNumber(prevState => prevState - 1)
    }

    // const handleSaveLogo = () => {

    //     // const imageNameSplitArray = values?.logo?.name?.split('.')

    //     // // console.log(imageNameSplitArray[imageNameSplitArray?.length - 1])

    //     // let data = JSON.stringify({
    //     //     query: `query {
    //     //   imagePreSignedUrls(
    //     //       imageType:logo,
    //     //       contentType:${imageNameSplitArray[imageNameSplitArray?.length - 1] ? "pngImage" : "jpegImage"}
    //     //   ) {
    //     //       presignedUploadUrl
    //     //       publicUrl
    //     //   }
    //     // }`,

    //     //     variables: {},
    //     // });


    //     // let config = {
    //     //     method: 'post',
    //     //     url: `${process.env.REACT_APP_SERVER_BASE_URL_GRAPHQL}`,
    //     //     headers: {
    //     //         'Authorization': `Bearer ${accessToken}`,
    //     //         'Content-Type': 'application/json'
    //     //     },
    //     //     data: data
    //     // };

    //     // axios(config)
    //     //     .then(function (response) {
    //     //         console.log("first response handleSaveLogo", response?.data?.data?.imagePreSignedUrls?.publicUrl);

    //     //         setOnboardingForm({
    //     //             ...onboardingForm,
    //     //             CompanyInformation: {
    //     //                 ...onboardingForm.CompanyInformation,
    //     //                 logoImageUrl: response?.data?.data?.imagePreSignedUrls?.publicUrl,
    //     //             }
    //     //         })

    //     //         let config2 = {
    //     //             method: 'put',
    //     //             url: response?.data?.data?.imagePreSignedUrls?.presignedUploadUrl,
    //     //             headers: {
    //     //                 'x-amz-acl': 'public-read',
    //     //                 'Content-Type': 'image/jpeg'
    //     //             },
    //     //             data: values?.logo
    //     //         };

    //     //         axios(config2)
    //     //             .then(function (response) {
    //     //                 console.log("second response handleSaveLogo", response.data);
    //     //                 setOnBoardingFormPage(formPageList.FinishedPages.fullFinished)
    //     //                 setLoading(false)


    //     //                 handleNextStep()


    //     //             })
    //     //             .catch(function (error) {
    //     //                 console.log("second error handleSaveLogo", error?.response);
    //     //             });

    //     //     })
    //     //     .catch(function (error) {
    //     //         console.log("first error handleSaveLogo", error?.response);
    //     //     });


    // }

    // console.log(values)

    return (
        <OnboardingLayout>

            <Box>
                <BasicInputComponent
                    name="about"
                    label="Please type your company motto"
                    placeholder="Include a sentence about your company motto"
                    error={touched?.about && errors?.about}
                    value={values?.about}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />


                <Box sx={{ mt: { xs: '50px' }, }} >
                    <ReactQuillEditor
                        name="description"
                        value={values?.description}
                        setValue={(e) => setFieldValue("description", e)}
                        placeholder="Include 2-3 sentences about your company"
                        className="react_quill_mystyle"
                        error={touched?.description && errors?.description}
                        setFieldTouched={setFieldTouched}
                        label="Please describe your company"
                    />
                </Box>

            </Box>

            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: 3 },
                    justifyContent: 'space-between',
                    mt: '20px',
                }}
            >

                <TextButtonOne onClick={handlePreviousStep} />

                {
                    !isButtonAktive ? (
                        <BlackButtonOne
                            text="Next Step"
                            handleClick={handleNextStep}
                            styles={{ maxWidth: '160px' }}
                            isButtonAktive={true}
                        />
                    ) : (
                        <BlackButtonOne
                            text="Skip Step"
                            handleClick={handleSkipStep}
                            styles={{ maxWidth: '160px' }}
                            isButtonAktive={true}
                        />
                    )
                }

            </Box>

        </OnboardingLayout>
    )
}

export default CompanyInformationDescriptions