import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch } from 'react-redux';


import {
    SingleApp,
    CompanyApp,
    InternApp,
    UniversityApp,
    EmployifApp,
} from "app"
import ThemeProvider from 'theme';
import { countriesFunc } from 'redux/parameters/countries';

const renderApp = () => {

    switch (process.env.REACT_APP_TARGET_APP) {
        case "company":
            return <CompanyApp />;
        case "intern":
            return <InternApp />;
        case "university":
            return <UniversityApp />;
        case "employif":
            return <EmployifApp />;
        default:
            return <SingleApp />;
    }

}




const MainApp = () => {



    // const dispatch = useDispatch()
    // const localUser = localStorage.getItem("userAuthInfo") ? JSON.parse(localStorage.getItem("userAuthInfo")) : null
    // console.log(localUser)
    // useEffect(() => {

    //     // dispatch(countriesFunc({ accessToken: localUser?.accessToken }))

    // }, [])


    return (
        <HelmetProvider>
            <ThemeProvider>
                <div className="app">
                    <ToastContainer />
                    {renderApp()}
                </div>
            </ThemeProvider>
        </HelmetProvider>
    )
}

export default MainApp