import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    signinGoogleData: [],
    signinGoogleLoading: false,
    signinGoogleError: null,
};



export const signinGoogleFunc = createAsyncThunk("signinGoogle/signinGoogleFunc", async (token) => {


    var data1 = JSON.stringify({
        "token": token
    });

    var config = initialConfig('https://api.internif.com/auth/oauth/google/login', data1);

    const response = axios(config).then((res) => {
        console.log('sdfasdfadf');
        const data = res.data;
        return data;
    }).catch((res) => {
        console.log('aaaa');
        return res;
    });

    const data = await response;
    return data;
});


const signinGoogle = createSlice({
    name: "signinGoogle",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(signinGoogleFunc.pending, (state) => {
            state.signinGoogleLoading = true;
        });
        builder.addCase(signinGoogleFunc.fulfilled, (state, action) => {
            state.signinGoogleLoading = false;
            state.signinGoogleData = action.payload;
        });
        builder.addCase(signinGoogleFunc.rejected, (state, action) => {
            console.log('zzz');
            state.signinGoogleLoading = false;
            state.signinGoogleError = action.error.message;
        });
    }
});

export default signinGoogle.reducer;