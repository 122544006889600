import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";


const initialState = {
    languageLevelsData: null,
    languageLevelsLoading: false,
    languageLevelsError: null,
};


export const languageLevelsFunc = createAsyncThunk("languageLevels/languageLevelsFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Data {
            parameters {
              languageLevels {
                data {
                  _id
                  name
                }
              }
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const languageLevels = createSlice({
    name: "languageLevels",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(languageLevelsFunc.pending, (state) => {
            state.languageLevelsLoading = true;
        });
        builder.addCase(languageLevelsFunc.fulfilled, (state, action) => {
            state.languageLevelsLoading = false;
            state.languageLevelsData = action.payload.data?.parameters.languageLevels?.data;
        });
        builder.addCase(languageLevelsFunc.rejected, (state, action) => {
            state.languageLevelsLoading = false;
            state.languageLevelsError = action.error.message;
        });
    }
});

export default languageLevels.reducer;