import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
  internEducationsData: null,
  internEducationsLoading: false,
  internEducationsError: null,
};


export const internEducationsFunc = createAsyncThunk("internEducations/internEducationsFunc", async (values) => {

  var data1 = JSON.stringify({
    query: `query Education {
          intern {
            cv {
              education {
                highSchoolEducations {
                  name
                  department {
                    _id
                    name
                  }
                  startDate
                  finishDate
                }
                universityEducations {
                  university {
                    _id
                    name
                  }
                  department {
                    _id
                    name
                  }
                  startDate
                  finishDate
                }
              }
            }
          }
        }`
  });

  var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

  const response = axios(config).then((res) => {
    const data = res.data;
    return data;
  }).catch((err) => {
    return err;
  });

  const data = await response;
  return data;
});


const internEducations = createSlice({
  name: "internEducations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(internEducationsFunc.pending, (state) => {
      state.internEducationsLoading = true;
    });
    builder.addCase(internEducationsFunc.fulfilled, (state, action) => {
      // console.log(action.payload)
      state.internEducationsLoading = false;
      state.internEducationsData = action.payload.data?.intern?.cv?.education;
      state.internEducationsError = action.payload.errors;
    });
    builder.addCase(internEducationsFunc.rejected, (state, action) => {
      state.internEducationsLoading = false;
      state.internEducationsError = action.error;
    });
  }
});

export default internEducations.reducer;