import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { NotFound404 } from 'pages/GeneralPages'
import EmployifPages from 'pages/EmployifPages'


const EmployifAppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="" element={<EmployifPages.EmployifAuthPages />} >

                    <Route path="/" element={<EmployifPages.EmployifSignin />} />
                    <Route path="/signin" element={<EmployifPages.EmployifSignin />} />
                    <Route path="/forget-password" element={<EmployifPages.EmployifForgetPasswordRequest />} />
                    {/* <Route path={RouterSettings.company.auth.signup} element={<CompanyPages.CompanySignup />} />
                    <Route path={RouterSettings.company.auth.verifyEmail} element={<CompanyPages.CompanyVerifyEmail />} />
                    <Route path={RouterSettings.company.auth.signupSuccessfull} element={<CompanyPages.CompanySignupSuccessfull />} />
                    <Route path={RouterSettings.company.auth.forgetPasswordSuccessfull} element={<CompanyPages.CompanyForgetPasswordSuccessfull />} />
                    <Route path={RouterSettings.company.auth.resetPasswordRequest} element={<CompanyPages.CompanyResetPassword />} />
                    <Route path={RouterSettings.company.auth.resetPasswordSuccessfull} element={<CompanyPages.CompanyResetPasswordSuccessfull />} />
                    <Route path={RouterSettings.company.auth.signin} element={<CompanyPages.CompanySignin />} /> */}
                    <Route path="*" element={<NotFound404 />} />

                </Route>
            </Routes>

        </BrowserRouter>
    )
}

export default EmployifAppRouter