import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';

import 'react-circular-progressbar/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import './sass/main.scss'

import MainApp from './MainApp';

import store from 'redux/store';
import client from 'apollo/store';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <MainApp />
      </Provider>
    </ApolloProvider>
  </React.StrictMode>
);