import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Button, Card, CardContent } from '@mui/material';
import { SeoIllustration } from '../assets';
import { BlackButtonOne } from 'components';
// import BlackButtonOne from '../../../../../Components/Buttons/BlackButtonOne';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  // boxShadow: 'none',
  // textAlign: 'center',
  // backgroundColor: "rgba(255, 152, 55, 0.5)",
  backgroundColor: "#fff",
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

// ----------------------------------------------------------------------

AppWelcome.propTypes = {
  displayName: PropTypes.string,
};

export default function AppWelcome({ displayName }) {
  return (
    <RootStyle>
      <CardContent
        sx={{
          p: { md: 0 },
          pl: { md: 5 },
          // color: 'grey.800',
          color: '#555',
        }}
      >
        <Typography gutterBottom variant="h4" sx={{ fontFamily: 'Causten-Semibold' }}>
          Welcome back,
          <br /> {!displayName ? '' : displayName}!
        </Typography>

        <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480, mx: 'auto', fontFamily: 'Causten-Regular' }}>
          If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything
        </Typography>

        {/* <Button
          // variant="contained"
          to="#"
          component={RouterLink}
          sx={{
            backgroundColor: '#FF9737'
          }}
        >
          Go Now
        </Button> */}

        <BlackButtonOne
          text="Go Now"
        />

      </CardContent>

      <SeoIllustration
        sx={{
          p: 3,
          width: 360,
          margin: { xs: 'auto', md: 'inherit' },
        }}
      />
    </RootStyle>
  );
}
