import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";


const initialState = {
    skillsData: null,
    skillsLoading: false,
    skillsError: null,
};


export const skillsFunc = createAsyncThunk("skills/skillsFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Data {
            parameters {
              skills {
                data {
                  _id
                  name
                }
              }
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const skills = createSlice({
    name: "skills",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(skillsFunc.pending, (state) => {
            state.skillsLoading = true;
        });
        builder.addCase(skillsFunc.fulfilled, (state, action) => {
            state.skillsLoading = false;
            state.skillsData = action.payload.data?.parameters.skills?.data;
        });
        builder.addCase(skillsFunc.rejected, (state, action) => {
            state.skillsLoading = false;
            state.skillsError = action.error.message;
        });
    }
});

export default skills.reducer;