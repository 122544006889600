import React, { useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box } from '@mui/material';
import { useOnboardingContext } from 'layouts/OnboardingLayout/OnboardingContext/OnboardingContext'
import { BasicInputComponent } from 'components';
import { DatePickerComponent } from 'components';
import CertificateList from './2-4-CertificatesForm/CertificateList';
import { TextButtonOne } from 'components';
import { BlackButtonOne } from 'components';
import { OnboardingLayout } from 'layouts/OnboardingLayout';



const ValidationSchema = yup.object().shape({
    name: yup.string()
        .required("Required!"),
    organization: yup.string()
        .required("Required!"),
    issueDate: yup.string()
        .required("Required!"),
    expirationDate: yup.string()
        .required("Required!"),
    stillAttending: yup.boolean()
        .required("Required!"),
})


const CertificatesForm = () => {

    const [isForm, setIsForm] = useState(false)
    const { setCurrentPage, onboardingPageList, setOnboardingFormNumber } = useOnboardingContext()


    const {
        errors,
        values,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
    } = useFormik({
        initialValues: {
            name: '',
            organization: '',
            issueDate: '',
            expirationDate: '',
            stillAttending: false,
        },
        validationSchema: ValidationSchema,
        onSubmit: () => {
            //  console.log("onSubmit")
        },
    })



    const handleNextStep = () => {
        setOnboardingFormNumber(prevState => prevState + 1)
        setCurrentPage(onboardingPageList[2]?.pages[0])
    }


    const handleSkipStep = () => {
        setOnboardingFormNumber(prevState => prevState + 1)
        setCurrentPage(onboardingPageList[2]?.pages[0])
    }


    const handleBackStep = () => {
        setOnboardingFormNumber(prevState => prevState - 1)
        setCurrentPage(onboardingPageList[1]?.pages[2])
    }


    return (
        <OnboardingLayout>

            {
                isForm ? (<CertificateList setIsForm={setIsForm} />) : (

                    <Box sx={{ my: { xs: "50px" }, }} >

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', lg: 'row' },
                                gap: 3,
                            }}
                        >

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column' },
                                    width: '100%',
                                    gap: 3,
                                }}
                            >
                                <BasicInputComponent
                                    name="name"
                                    label="Certificate Name"
                                    placeholder="Enter certificate name"
                                    error={touched?.name && errors?.name}
                                    value={values?.name}

                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                />
                                <BasicInputComponent
                                    name="organization"
                                    label="Organization Name"
                                    placeholder="Enter organization name"
                                    error={touched?.organization && errors?.organization}
                                    value={values?.organization}

                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                />
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: { xs: 'column', md: 'column' },
                                    width: '100%',
                                    gap: 3,
                                }}>
                                <DatePickerComponent
                                    name="issueDate"
                                    label="Issue Date"
                                    placeholder="Enter issue"
                                    error={touched?.issueDate && errors?.issueDate}
                                    value={values?.issueDate}

                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                />
                                <DatePickerComponent
                                    name="expirationDate"
                                    label="Expiration Date "
                                    placeholder="Enter expiration date"
                                    error={touched?.expirationDate && errors?.expirationDate}
                                    value={values?.expirationDate}

                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                />
                            </Box>

                        </Box>




                    </Box>

                )

            }


            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: 3 },
                    justifyContent: 'space-between',
                    mt: '20px',
                }}
            >
                <TextButtonOne
                    text="back"
                    onClick={handleBackStep}
                />

                <BlackButtonOne
                    text="Next Step"
                    handleClick={handleNextStep}
                    styles={{ maxWidth: '160px' }}
                    // isButtonAktive={isButtonAktive}
                    isButtonAktive={true}
                />
            </Box>
        </OnboardingLayout>
    )
}

export default CertificatesForm