import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    onboardCreateUsageReasonData: null,
    onboardCreateUsageReasonLoading: false,
    onboardCreateUsageReasonError: null,
};



export const onboardCreateUsageReasonFunc = createAsyncThunk("onboardCreateUsageReason/onboardCreateUsageReasonFunc", async (values) => {

    // console.log(values)
    var data1 = JSON.stringify({
        query: `mutation CreateCustomUsageReason($usageReasonInput: CustomUsageReasonInput!) {
            createCustomUsageReason(usageReasonInput: $usageReasonInput) {
              _id
            }
          }`,
        variables: {
            "usageReasonInput": {
                "title": values.title
            }
        }
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        console.log(err.response.data);
        return err;
    });

    const data = await response;
    return data;
});


const onboardCreateUsageReason = createSlice({
    name: "onboardCreateUsageReason",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(onboardCreateUsageReasonFunc.pending, (state) => {
            state.onboardCreateUsageReasonLoading = true;
        });
        builder.addCase(onboardCreateUsageReasonFunc.fulfilled, (state, action) => {
            console.log(action.payload)
            state.onboardCreateUsageReasonLoading = false;
            state.onboardCreateUsageReasonData = action.payload.data?.createCustomUsageReason?._id;
            state.onboardCreateUsageReasonError = action.payload.errors;
        });
        builder.addCase(onboardCreateUsageReasonFunc.rejected, (state, action) => {
            console.log(action.error)
            state.onboardCreateUsageReasonLoading = false;
            state.onboardCreateUsageReasonError = action.error.message;
        });
    }
});


export default onboardCreateUsageReason.reducer;