import React from 'react';

import {
  GoToHome,
  UpperTextContainer,
  BottomLink,
  AuthLayout,
  FormLayout,
} from 'layouts/AuthLayout';
import { ForgetPasswordImage } from 'assets'
import ForgetPasswordFormRequestForm from './_ForgetPasswordRequestForm';


const ForgetPasswordRequest = ({ authImage, bottomLİnk, handleForgetPasswordRequest }) => {
  return (
    <AuthLayout authImage={authImage}>

      <GoToHome />

      <UpperTextContainer
        boxStyle={{ maxWidth: { xs: '330px', sm: '330px', lg: '477px' }, }}
        icon={<img src={ForgetPasswordImage} alt="Forget Password" />}
        iconStyle={{ width: { xs: '40px', md: '64px' }, }}
        title="Forgot Password"
        description="We’ll send a link to your e-mail address to reset your password. The link expire in 15 minutes."
      />

      <FormLayout>
        <ForgetPasswordFormRequestForm handleForgetPasswordRequest={handleForgetPasswordRequest} />
      </FormLayout>

      <BottomLink
        text="Already have an account?"
        linkText="Sign In"
        link={bottomLİnk}
      />

    </AuthLayout>
  );
};

export default ForgetPasswordRequest;
