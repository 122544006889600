import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";


const initialState = {
    usageReasonsData: null,
    usageReasonsLoading: false,
    usageReasonsError: null,
};


export const usageReasonsFunc = createAsyncThunk("usageReasons/usageReasonsFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Data {
            usageReasons {
              data {
                _id
                title
              }
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const usageReasons = createSlice({
    name: "usageReasons",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(usageReasonsFunc.pending, (state) => {
            state.usageReasonsLoading = true;
        });
        builder.addCase(usageReasonsFunc.fulfilled, (state, action) => {
            state.usageReasonsLoading = false;
            state.usageReasonsData = action.payload.data?.usageReasons?.data;
            state.usageReasonsError = action.payload.errors;
        });
        builder.addCase(usageReasonsFunc.rejected, (state, action) => {
            state.usageReasonsLoading = false;
            state.usageReasonsError = action.error.message;
        });
    }
});

export default usageReasons.reducer;