import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";
import { useSelector } from "react-redux";

const initialState = {
    personalInformationData: [],
    personalInformationLoading: false,
    personalInformationError: null,
};



export const personalInformationFunc = createAsyncThunk("personalInformation/personalInformationFunc", async (values) => {

    console.log(values);
    var data1 = JSON.stringify({
        query: `mutation Mutation($personalInformationInput: PersonalInformationInput!) {
            onboardPersonalInformationForIntern(personalInformationInput: $personalInformationInput) {
              success
            }
          }`,
        variables: {
            "personalInformationInput": {
                "fullName": values.fullName,
                "birthDate": values.date,
                "country": { "_id": values.selectedCountry },
                "city": { "_id": values.selectedCity },
                "description": values.personalDetail ?? ""
            }
        }
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        console.log(err.response.data);
        return err;
    });

    const data = await response;
    return data;
});


const personalInformation = createSlice({
    name: "personalInformation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(personalInformationFunc.pending, (state) => {
            state.personalInformationLoading = true;
        });
        builder.addCase(personalInformationFunc.fulfilled, (state, action) => {
            state.personalInformationLoading = false;
            state.personalInformationData = action.payload.data?.onboardPersonalInformationForIntern;
            state.personalInformationError = action.payload.errors;
        });
        builder.addCase(personalInformationFunc.rejected, (state, action) => {
            state.personalInformationLoading = false;
            state.personalInformationError = action.error;
        });
    }
});

export default personalInformation.reducer;