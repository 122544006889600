import React, { useState } from 'react';

import {
    Box,
    Drawer,
    List,
    IconButton,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';



const OnboardingSideMenu = () => {


    const [state, setState] = useState(false);


    const OnboardingMenu = () => {

        return (
            <Box sx={{ width: "250px", height: "100%", }} >
                <List>

                </List>
            </Box>
        )
    };


    return (
        <>
            <IconButton
                onClick={() => setState(true)}
                sx={{
                    backgroundColor: "transparent",
                    display: { xs: "flex", md: 'none' },
                }}
            >
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor='left'
                open={state}
                onClose={() => setState(false)}
            >
                <OnboardingMenu />
            </Drawer>
        </>
    );
};

export default OnboardingSideMenu
