import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    onboardExperiencesData: null,
    onboardExperiencesLoading: false,
    onboardExperiencesError: null,
    experiencesArray: [],
};



export const onboardExperiencesFunc = createAsyncThunk("onboardExperiences/onboardExperiencesFunc", async (values) => {

    console.log('!!!!! ',values);
    var data1 = JSON.stringify({
        query: `mutation OnboardExperiences($experiencesInput: [ExperienceInput!]!) {
            onboardExperiences(experiencesInput: $experiencesInput) {
              success
            }
          }`,
        variables: {
            "experiencesInput": values.experiencesArrayForDispatch
          }
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        console.log(err.response.data);
        return err;
    });

    const data = await response;
    return data;
});


const onboardExperiences = createSlice({
    name: "onboardExperiences",
    initialState,
    reducers: {
        setExperiencesArray: (state, action) => {
            state.experiencesArray = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(onboardExperiencesFunc.pending, (state) => {
            state.onboardExperiencesLoading = true;
        });
        builder.addCase(onboardExperiencesFunc.fulfilled, (state, action) => {
            state.onboardExperiencesLoading = false;
            state.onboardExperiencesData = action.payload.data?.onboardExperiences;
            state.onboardExperiencesError = action.payload.errors;
            console.log(action.payload.data?.onboardExperiences);
        });
        builder.addCase(onboardExperiencesFunc.rejected, (state, action) => {
            state.onboardExperiencesLoading = false;
            state.onboardExperiencesError = action.error.message;
        });
    }
});

export const {
    setExperiencesArray
} = onboardExperiences.actions;


export default onboardExperiences.reducer;