
import React from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import { BackdropFormLayout } from 'layouts';
import { BlackButtonOne, WhiteButtonOne, ReactQuillEditor, BasicInputComponent } from 'components';


const ValidationSchema = yup.object().shape({
  title: yup.string()
    .required("Required!"),
  desc: yup.string()
    .required("Required!"),
  isArchived: yup.string()
    .required("Required!"),
})

const CoverLetterCreateAndUpdate = ({ formBackdrop, setFormBackdrop, handleClick }) => {

  const {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
  } = useFormik({

    validationSchema: ValidationSchema,
    initialValues: {
      title: '',
      desc: '',
      isArchived: false,
    },
    onSubmit: (values, { resetForm }) => {

      resetForm({
        title: '',
        desc: '',
        isArchived: false,
      })
    },

  })

  const CoverLetterTips = () => {
    return (
      <Typography
        sx={{
          fontFamily: 'Causten-Medium',
          fontSize: '12px',
          lineHeight: '18px',
          color: '#7D8898',
        }}
      >
        Cover Letter Tips.
      </Typography>
    )
  }

  const { title, desc } = values
  const isAddButtonActive = (title !== "" && desc !== "")

  return (
    <BackdropFormLayout
      formBackdrop={formBackdrop}
      setFormBackdrop={setFormBackdrop}
      backdropNavbarText="My Cover Letters"
      tips={<CoverLetterTips />}
    >

      <Typography
        sx={{
          fontFamily: 'Causten-Medium',
          fontSize: '24px',
          lineHeight: '28px',
          color: '#0E1436',
          mb: { xs: '56px' },
        }}
      >
        Cover Letter Form
      </Typography>

      <Grid container spacing={3}>

        <Grid item xs={12}>
          <BasicInputComponent
            dashboard
            name="title"
            // type = "text"
            label="Title"
            placeholder="Enter your title"

            error={touched?.title && errors.title}
            value={values.title}
            handleBlur={handleBlur}
            handleChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <ReactQuillEditor
            name="desc"
            label="Description"
            placeholder="Description"
            className="react_quill_mystyle"
            value={values.desc}
            error={touched?.desc && errors.desc}
            setFieldTouched={() => setFieldTouched('desc', true)}
            setValue={(e) => setFieldValue("desc", e)}
          />

          <FormControlLabel
            componentsProps={{
              "typography": {
                fontFamily: 'Causten-Regular',
                fontSize: { xs: "12px", md: "12px", },
                lineHeight: { xs: "20px", md: "20px", },
                color: values.finishDate === "continue" ? "#0E1436" : "#70798D",
              },
            }}

            label="Archive"
            control={
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                id="isArchived"
                name="isArchived"
                checked={values?.isArchived}
                onChange={handleChange}
              />
            }

            sx={{ mt: '20px' }}
          />

        </Grid>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            my: { xs: '40px' },
            justifyContent: 'space-between',
            gap: '20px',
          }}
        >
          <WhiteButtonOne
            text="Cancel"
            handleClick={() => setFormBackdrop(false)}
            isButtonAktive={true}
          />

          <BlackButtonOne
            text="Save"
            handleClick={handleSubmit}
            isButtonAktive={isAddButtonActive}
          />

        </Box>

      </Grid>

    </BackdropFormLayout>
  )
}

export default CoverLetterCreateAndUpdate