import React, {
    useState
} from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, IconButton } from '@mui/material';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { BlackButtonOne, BasicInputComponent } from 'components';


const ValidationSchema = Yup.object({
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$!%*#?&.]{8,}$/,
            "Must Contain One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required('Required!'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required("Required!")
})

const ResetPasswordForm = ({ handleResetPassword }) => {

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        values,
        touched
    } = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: ValidationSchema,
        onSubmit: () => {
            handleResetPassword({ values });
        }
    })


    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                gap: { xs: 2, sm: 3 },
                alignItem: 'center',
            }}
        >

            <BasicInputComponent
                name="password"
                type={!showPassword ? "password" : "text"}
                label="New Password"
                placeholder="Enter your new password"

                value={values?.password}
                handleBlur={handleBlur}
                handleChange={handleChange}
                error={touched.password && errors.password}
            >
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {
                        !showPassword ? (
                            <VisibilityOffOutlinedIcon />
                        ) : (
                            <VisibilityOutlinedIcon />
                        )
                    }
                </IconButton>
            </BasicInputComponent>

            <BasicInputComponent
                name="confirmPassword"
                type={!showPassword2 ? "password" : "text"}
                label="Verify New Password"
                placeholder="Enter your new password again"

                value={values?.confirmPassword}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={touched.confirmPassword && errors.confirmPassword}
            >
                <IconButton onClick={() => setShowPassword2(!showPassword2)}>
                    {
                        !showPassword2 ? (
                            <VisibilityOffOutlinedIcon />
                        ) : (
                            <VisibilityOutlinedIcon />
                        )
                    }
                </IconButton>
            </BasicInputComponent>


            <BlackButtonOne
                text="Change Password"
                handleClick={handleSubmit}
                isButtonAktive={(Object.keys(touched).length > 0) && !(Object.keys(errors).length > 0)}
                styles={{
                    m: 'auto',
                    mt: { xs: '28px', sm: '8px' },
                    width: { xs: '100%', sm: '172px' },
                }}
            />


        </Box>
    );
};

export default ResetPasswordForm;