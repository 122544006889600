import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    highSchoolDepartmentsData: [],
    highSchoolDepartmentsLoading: false,
    highSchoolDepartmentsError: null,
};





export const highSchoolDepartmentsFunc = createAsyncThunk("highSchoolDepartments/highSchoolDepartmentsFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query HighSchoolDepartments {
            parameters {
              highSchoolDepartments {
                data {
                  _id
                  name
                }
              }
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const highSchoolDepartments = createSlice({
    name: "highSchoolDepartments",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(highSchoolDepartmentsFunc.pending, (state) => {
            state.highSchoolDepartmentsLoading = true;
        });
        builder.addCase(highSchoolDepartmentsFunc.fulfilled, (state, action) => {
            // console.log(action?.payload?.data?.parameters?.highSchoolDepartments?.data)
            state.highSchoolDepartmentsLoading = false;
            state.highSchoolDepartmentsData = action.payload?.data?.parameters?.highSchoolDepartments?.data;
        });
        builder.addCase(highSchoolDepartmentsFunc.rejected, (state, action) => {
            state.highSchoolDepartmentsLoading = false;
            state.highSchoolDepartmentsError = action.error.message;
        });
    }
});

export default highSchoolDepartments.reducer;