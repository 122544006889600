import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    internLanguagesData: null,
    internLanguagesLoading: false,
    internLanguagesError: null,
};


export const internLanguagesFunc = createAsyncThunk("internLanguages/internLanguagesFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Language {
            intern {
              cv {
                foreignLanguages {
                  language {
                    _id
                  }
                  level {
                    _id
                  }
                }
              }
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const internLanguages = createSlice({
    name: "internLanguages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(internLanguagesFunc.pending, (state) => {
            state.internLanguagesLoading = true;
        });
        builder.addCase(internLanguagesFunc.fulfilled, (state, action) => {
            state.internLanguagesLoading = false;
            state.internLanguagesData = action.payload.data?.intern?.cv?.foreignLanguages;
            state.internLanguagesError = action.payload.errors;
        });
        builder.addCase(internLanguagesFunc.rejected, (state, action) => {
            state.internLanguagesLoading = false;
            state.internLanguagesError = action.error;
        });
    }
});

export default internLanguages.reducer;