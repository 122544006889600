import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import AuthImage from '../../EmployifAssets/employif_login.jpg'
import { RouterSettings } from 'routers'
import { ForgetPasswordRequest } from 'layouts/AuthLayout'
import { forgotPasswordFunc } from 'redux/auth/forgotPassword';
import { LottieLoadingAndSuccessfull } from 'components';


const EmployifForgetPasswordRequest = () => {


  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  // const { forgotPasswordLoading, forgotPasswordError } = useSelector(state => state.forgotPassword);

  const handleForgetPasswordRequest = ({ values }) => {
    // console.log(values)
    // dispatch(forgotPasswordFunc(values.email));

    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      toast.error("User could not find!", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }, 1000)


  }




  // useEffect(() => {

  //   if (forgotPasswordLoading) {

  //     setLoading(true)

  //     setTimeout(() => {

  //       setLoading(false)

  //       if (forgotPasswordError === "EmailIsNotRegistered") {

  //         toast.error("User could not find!", {
  //           position: "bottom-center",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });

  //       } else {

  //         toast.error(forgotPasswordError, {
  //           position: "bottom-center",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //         });

  //       }

  //     }, 2000)

  //   }

  // }, [forgotPasswordLoading, forgotPasswordError]);


  return (
    <>
      <LottieLoadingAndSuccessfull loading={loading} />

      <ForgetPasswordRequest
        authImage={AuthImage}
        handleForgetPasswordRequest={handleForgetPasswordRequest}
        bottomLİnk={RouterSettings.singleApp.auth.signin}
      />
    </>
  )
}

export default EmployifForgetPasswordRequest