import React, {
    useEffect
} from 'react'

import {
    Box,
    Button,
    Typography,
    useMediaQuery
} from '@mui/material'

import axios from 'axios'
import { gapi } from 'gapi-script';
// import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from 'react-google-login';
import { useLinkedIn } from 'react-linkedin-login-oauth2';

import GoogleIcon from '../../../assets/icons/auth/google-icon.png'
import LinkedinIcon from '../../../assets/icons/auth/linkedin-icon.png'

// import { useAuthContext } from '../../../Contexts/AuthContext/AuthContext'
// import { COLORS } from '../../../Constants';
// import toast from 'react-hot-toast';



const SocialLoginButtons = ({ text, buttonText }) => {

    const isMini = useMediaQuery('(max-width:360px)')
    const isMobile = useMediaQuery('(max-width:600px)')
    // const { setUser, setAccessToken, setLoading, setError } = useAuthContext()


    // ###################################################################################
    // ########################        GOOGLE LOGIN          #############################
    // ###################################################################################
    useEffect(() => {

        function start() {
            gapi.auth2.getAuthInstance({
                clientId: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
                scope: ""
            })
        }

        gapi.load('client:auth2', start)

    }, [])

    const handleGoogleLogin = (response) => {

        let data = JSON.stringify({
            "token": response.tokenObj.id_token,
            "role": "company"
        });

        let config = {
            method: 'post',
            url: `${process.env.REACT_APP_SERVER_BASE_URL_OAUTH}google/login`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(response.data);
                // if (response.data.user.role === 'company') {
                //     toast(
                //         "You loged in successfully!",
                //         {
                //             style: {
                //                 background: COLORS.success,
                //                 color: COLORS.successReverse,
                //                 fontFamily: 'Causten-Regular',
                //                 borderRadius: '8px',
                //             },
                //         }
                //     )
                //     localStorage.setItem("InternifBusinessUser", JSON.stringify(response.data))
                //     localStorage.setItem("InternifBusinessToken", response.data.accessToken)
                //     localStorage.setItem("InternLastLoginTime", new Date(Date.now()).getTime());
                //     setUser(response.data)
                //     setAccessToken(response.data.accessToken)
                //     setLoading(false)
                //     setError(false)
                // } else {
                //     setLoading(false)
                //     setError("User is not business user!")
                // }
            })
            .catch(function (error) {

                let config2 = {
                    method: 'post',
                    url: `${process.env.REACT_APP_SERVER_BASE_URL_OAUTH}google/register`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };

                axios(config2)
                    .then(function (response) {

                        // if (response.data.user.role === 'company') {
                        //     // toast(
                        //     //     "You registered and loged in successfully!",
                        //     //     {
                        //     //         style: {
                        //     //             background: COLORS.success,
                        //     //             color: COLORS.successReverse,
                        //     //             fontFamily: 'Causten-Regular',
                        //     //             borderRadius: '8px',
                        //     //         },
                        //     //     }
                        //     // )
                        //     localStorage.setItem("InternifBusinessUser", JSON.stringify(response.data))
                        //     localStorage.setItem("InternifBusinessToken", response.data.accessToken)
                        //     localStorage.setItem("InternLastLoginTime", new Date(Date.now()).getTime());
                        //     // setUser(response.data)
                        //     // setAccessToken(response.data.accessToken)
                        //     // setLoading(false)
                        //     // setError(false)
                        // } else {
                        //     // setLoading(false)
                        //     // setError("User is not business user!")
                        // }
                    })
                    .catch(function (error) {
                        console.log(error.response)
                        // setLoading(false)
                        // setError(error?.response)
                    });
            });

    }

    const { signIn } = useGoogleLogin({
        clientId: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID,
        onSuccess: handleGoogleLogin,
    })
    // ###################################################################################









    // ###################################################################################
    // ########################        LINKEDIN LOGIN          #############################
    // ###################################################################################
    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_ID,
        redirectUri: "https://app.internif.com/linkedin",
        scope: ["r_liteprofile", "r_emailaddress"],
        onSuccess: (code) => {
            console.log(code);

            const data = JSON.stringify({
                "token": code,
            });

            const config = {
                method: 'post',
                url: `${process.env.REACT_APP_SERVER_BASE_URL_OAUTH}linkedin/login`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(function (response) {

                    console.log("linkedin login successful ", response.data);

                    // if (response.data.user.role === 'company') {

                    //     toast(
                    //         "You loged in successfully!",
                    //         {
                    //             style: {
                    //                 background: COLORS.success,
                    //                 color: COLORS.successReverse,
                    //                 fontFamily: 'Causten-Regular',
                    //                 borderRadius: '8px',
                    //             },
                    //         }
                    //     )

                    //     localStorage.setItem("InternifBusinessUser", JSON.stringify(response.data))
                    //     localStorage.setItem("InternifBusinessToken", response.data.accessToken)
                    //     localStorage.setItem("InternLastLoginTime", new Date(Date.now()).getTime());
                    //     setUser(response.data)
                    //     setAccessToken(response.data.accessToken)
                    //     setLoading(false)
                    //     setError(false)
                    // } else {
                    //     setLoading(false)
                    //     setError("User is not business user!")
                    // }
                })
                .catch(function (error) {
                    console.log("linkedin login error ", error.response)
                    // setLoading(false)
                    // setError(error?.response)
                });

        },
        onError: (error) => {
            console.log(error);
        },

    });
    // ###################################################################################


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', }} >

            {/* ********************* Buttons ******************* */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: isMini ? 'column' : 'row-reverse',
                        sm: 'column',
                    },
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    gap: { xs: '15px', sm: '16px', lg: '1vh' }
                }}
            >

                <Button
                    onClick={linkedInLogin}
                    sx={{
                        width: '100%',
                        background: "#0077B5",
                        borderRadius: '8px',
                        color: '#fff',
                        textTransform: 'none',

                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                        height: '48px',
                        ':hover': {
                            background: "#0077b5",
                        }
                    }}
                >
                    <img src={LinkedinIcon} alt="" width="32" height="32" />
                    <Typography
                        sx={{
                            fontFamily: 'Causten-SemiBold',
                            fontSize: { xs: '16px', sm: '16px', lg: '16px' },
                            lineHeight: { xs: '19.2px', sm: '19.2px', lg: '19.2px' },
                            color: '#fff',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {isMobile ? buttonText : 'Continue with Linkedin'}
                    </Typography>
                </Button>


                <Button
                    onClick={signIn}
                    sx={{
                        width: '100%',
                        background: "#fff",
                        borderRadius: '8px',
                        color: '#0E1436',
                        textTransform: 'none',
                        border: "1px solid #D1D5D8",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                        height: '48px',
                        ':hover': {
                            background: "#fff",
                        }
                    }}>
                    <img src={GoogleIcon} alt="" width="32" height="32" />
                    <Typography
                        sx={{
                            fontFamily: 'Causten-SemiBold',
                            fontSize: { xs: '16px', sm: '16px', lg: '16px' },
                            lineHeight: { xs: '19.2px', sm: '19.2px', lg: '19.2px' },
                            color: '#0E1436',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {isMobile ? buttonText : 'Continue with Google'}
                    </Typography>
                </Button>

            </Box>

            {/* ****************************  seperator ******************************** */}
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    gap: 2,
                    mb: { xs: '40px', sm: '16px', lg: '1vh' },
                    mt: { xs: '32px', sm: '16px', lg: '1vh' },
                }}
            >
                <Box sx={{ bgcolor: "#96A1BA", height: "1px", width: "100%" }} />
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                        fontFamily: 'Causten-SemiBold',
                        fontSize: { xs: '15px', sm: '16px', lg: '16px' },
                        lineHeight: { xs: '15px', sm: '16px', lg: '19px' },
                        color: '#96A1BA',
                    }}
                >
                    {text}
                </Typography>
                <Box sx={{ bgcolor: "#96A1BA", height: "1px", width: "100%" }} />
            </Box>

        </Box>
    )
}

export default SocialLoginButtons