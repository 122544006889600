import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    universityDepartmentsData: [],
    universityDepartmentsLoading: false,
    universityDepartmentsError: null,
};





export const universityDepartmentsFunc = createAsyncThunk("universityDepartments/universityDepartmentsFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query universityDepartments {
            parameters {
              universityDepartments {
                data {
                  _id
                  name
                }
              }
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const universityDepartments = createSlice({
    name: "universityDepartments",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(universityDepartmentsFunc.pending, (state) => {
            state.universityDepartmentsLoading = true;
        });
        builder.addCase(universityDepartmentsFunc.fulfilled, (state, action) => {
            // console.log(action?.payload)
            state.universityDepartmentsLoading = false;
            state.universityDepartmentsData = action.payload?.data?.parameters?.universityDepartments?.data;
        });
        builder.addCase(universityDepartmentsFunc.rejected, (state, action) => {
            state.universityDepartmentsLoading = false;
            state.universityDepartmentsError = action.error.message;
        });
    }
});

export default universityDepartments.reducer;