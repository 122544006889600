import React, { useState } from 'react'
import { motion } from "framer-motion"
import { Box, IconButton, Typography } from '@mui/material'

import StraightIcon from '@mui/icons-material/Straight';
import StarRoundedIcon from '@mui/icons-material/StarRounded';


const sliderExample = [
    {
        "id": "1",
        "comment": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A sit amet neque, id duis sed.",
        "name": "Ellen Rose",
        "stars": 1,
        "school": "Harvard",
        "grade": "Law 2nd Grade",
    },
    {
        "id": "2",
        "comment": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A sit amet neque, id duis sed.",
        "name": "Ellen Rose",
        "stars": 2,
        "school": "Harvard",
        "grade": "Law 2nd Grade",
    },
    {
        "id": "3",
        "comment": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. A sit amet neque, id duis sed.",
        "name": "Ellen Rose",
        "stars": 3,
        "school": "Harvard",
        "grade": "Law 2nd Grade",
    },
]

const sliderDuration = 1


const ImageSection = ({ bgImage, style }) => {

    return (
        <Box sx={style} >
            <img src={bgImage} alt="auth" style={{ height: '100%', }} />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: { md: '70px', lg: '100px' },
                    left: '12.5%',

                    width: '75%',
                    overflow: 'hidden',
                }}
            >

                <Slider />
            </Box>
        </Box>
    )
}

const Slider = () => {

    const [sliderNumber, setSliderNumber] = useState(0)

    const handleClickNext = () => {
        setSliderNumber((state) => {
            if (state >= sliderExample.length - 1) {
                return 0;
            }
            return state + 1;
        });
    };

    const handleClickPrev = () => {
        setSliderNumber((state) => {
            if (state === 0) {
                return sliderExample?.length - 1;
            }
            return state - 1;
        });
    };

    return (
        <>
            {
                sliderExample?.map((item, index) => {

                    if (sliderNumber !== index) return null;

                    return (
                        <motion.div
                            key={index}
                        // initial={{ translateX: '-100%' }}
                        // animate={{ translateX: '0%' }}
                        // transition={{ duration: sliderDuration }}
                        >
                            <Box
                                sx={{
                                    // transition: "all 1s ease-in",
                                    // position: 'absolute',
                                    // bottom: { md: '70px', lg: '100px' },
                                    // left: '12.5%',

                                    // width: '75%',
                                    // height: '40%',

                                    backgroundColor: "rgba(255, 255, 255, 0.6)",
                                    background: 'linear-gradient(135.85deg, rgba(255, 255, 255, 0.64) 17.26%, rgba(255, 255, 255, 0.1) 105.55%)',
                                    backdropFilter: "blur(30px)",

                                    color: '#fff',
                                    borderRadius: '16px',
                                    p: { md: '24px', lg: '28px', xl: '32px' },
                                    // height: '325px',
                                    overflow: 'hidden',
                                }}
                            >

                                <SliderItem
                                    key={item.id}
                                    number={index}
                                    comment={item.comment}
                                    name={item.name}
                                    stars={item.stars}
                                    school={item.school}
                                    grade={item.grade}

                                    sliderNumber={sliderNumber}
                                    handleClickNext={handleClickNext}
                                    handleClickPrev={handleClickPrev}
                                />
                            </Box>
                        </motion.div>
                    )
                })
            }
        </>
    )
}

const SliderItem = ({ comment, name, stars, school, grade, number, handleClickNext, handleClickPrev }) => {

    return (
        <Box sx={{ overflow: 'hidden', }} >

            <motion.div
                initial={{ translateX: '-100%' }}
                animate={{ translateX: '0%' }}
                transition={{ duration: sliderDuration }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Causten-Bold',
                        fontSize: { md: '18px', lg: '23px', xl: '28px' },
                        lineHeight: { md: '21px', lg: '33.6px' },
                        color: '#fff',
                    }}
                >
                    “{comment + number}”
                </Typography>
            </motion.div>

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: { md: '32px', lg: '42px', xl: '52px' },
                    mb: { md: '10px', lg: '13px', xl: '15px' },
                    overflow: 'hidden',
                }}
            >

                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: sliderDuration }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Causten-Bold',
                            fontSize: { md: '18px', lg: '23px', xl: '28px' },
                            lineHeight: { md: '20px', lg: '33.6px' },
                            color: '#fff',
                        }}
                    >
                        {name}
                    </Typography>
                </motion.div>

                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        overflow: 'hidden',
                    }}
                >
                    {
                        new Array(stars).fill(0).map((item, index) => (
                            <Box key={index}
                                sx={{
                                    width: { md: '18px', lg: '21px', xl: "24" },
                                    height: { md: '18px', lg: '21px', xl: "24" },
                                    overflow: 'hidden',
                                }}
                            >
                                <motion.div
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: sliderDuration }}
                                >
                                    <StarRoundedIcon sx={{ color: '#f89225' }} />
                                </motion.div>
                            </Box>
                        ))
                    }
                </Box>

            </Box>

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >

                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: sliderDuration }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Causten-Regular',
                            fontSize: { md: '18px', lg: '21px', xl: '24px' },
                            lineHeight: { md: '20px', lg: '24px', xl: '28px' },
                            color: '#fff'
                        }}
                    >
                        {school}, <br />
                        {grade}
                    </Typography>
                </motion.div>

                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        mr: { md: 0, lg: 0 },
                        color: '#fff',
                    }}
                >
                    <IconButton
                        onClick={handleClickPrev}
                        sx={{
                            width: { md: '50px', lg: '55px', xl: "60px" },
                            height: { md: '50px', lg: '55px', xl: "60px" },
                            transform: 'rotate(-90deg)',
                            color: '#fff',
                        }}
                    >
                        <StraightIcon />
                    </IconButton>
                    <IconButton
                        onClick={handleClickNext}
                        sx={{
                            width: { md: '50px', lg: '55px', xl: "60px" },
                            height: { md: '50px', lg: '55px', xl: "60px" },
                            transform: 'rotate(90deg)',
                            color: '#fff',
                        }}
                    >
                        <StraightIcon />
                    </IconButton>
                </Box>

            </Box>
        </Box>
    )
}

export default ImageSection