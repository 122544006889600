import { Box } from "@mui/material"

const FormLayout = ({ children }) => {
    return (
        <Box sx={{ width: { xs: '100%', sm: '340px', md: '320px', lg: '340px', xl: '360px' }, }}>
            {children}
        </Box>
    )
}

export default FormLayout