import React, {
    useState
} from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Typography, Link } from '@mui/material'

import { AlertPopup, BlackButtonOne } from 'components'


const styles = {
    text_link: {
        fontFamily: 'Causten-Bold',
        color: '#3BA1EB',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    inputLabel: {
        textAlign: 'left',
        position: 'absolute',
        top: '-20px',
        backgroundColor: '#000'
    }
}

const ValidationSchema = yup.object().shape({
    num1: yup.string().required("Required!"),
    num2: yup.string().required("Required!"),
    num3: yup.string().required("Required!"),
    num4: yup.string().required("Required!"),
    num5: yup.string().required("Required!"),
    num6: yup.string().required("Required!"),
})


const VerifyEmailForm = ({ handleVerifyEmail, handleResendCode }) => {

    const [isSendPopup, setIsSendPopup] = useState(false)

    const {
        errors,
        values,
        touched,
        // handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit
    } = useFormik({
        initialValues: {
            num1: "",
            num2: "",
            num3: "",
            num4: "",
            num5: "",
            num6: ""
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            handleVerifyEmail({ values })
        }

    })



    const handleChangeAndNext = (event) => {

        if (event.target.value.length > 0) {
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1].focus();
            event.preventDefault();
            setFieldValue(event.target.name, event.target.value.slice(0, 1));
        }

    }

    const handleKeyDown = (event) => { // for delete and go back input

        if (event.code === "Backspace") {
            if (event.target.value === "") {
                const form = event.target.form;
                const index = [...form].indexOf(event.target);
                // console.log(index)
                if (index !== -0) form.elements[index - 1].focus();
            }
            setFieldValue(event.target.name, event.target.value.slice(0, 0));
        }

    }



    return (
        <Box sx={{ mt: { xs: '10px', md: '80px' }, textAlign: 'center', }} >

            <AlertPopup
                open={isSendPopup}
                handleClose={() => setIsSendPopup(false)}
                questionText="Are you sure to delete?"
                buttonText="resend"
                handleClick={() => {
                    handleResendCode()
                    setIsSendPopup(false)
                }}
            />

            <form action="" style={{ width: '100%' }} onSubmit={handleSubmit}>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: { xs: 1 },
                        mb: { xs: '32px' },
                    }}
                >
                    <MyInput
                        name="num1"
                        errors={errors}
                        values={values?.num1}
                        touched={touched}
                        // handleOnEnter={handleChangeAndNext}
                        handleChange={handleChangeAndNext}
                        handleKeyDown={handleKeyDown}
                        handleBlur={handleBlur}
                    />
                    <MyInput
                        name="num2"
                        errors={errors}
                        values={values?.num2}
                        touched={touched}
                        // handleOnEnter={handleChangeAndNext}
                        handleChange={handleChangeAndNext}
                        handleKeyDown={handleKeyDown}
                        handleBlur={handleBlur}
                    />
                    <MyInput
                        name="num3"
                        errors={errors}
                        values={values?.num3}
                        touched={touched}
                        // handleOnEnter={handleChangeAndNext}
                        handleChange={handleChangeAndNext}
                        handleKeyDown={handleKeyDown}
                        handleBlur={handleBlur}
                    />
                    <MyInput
                        name="num4"
                        errors={errors}
                        values={values?.num4}
                        touched={touched}
                        // handleOnEnter={handleChangeAndNext}
                        handleChange={handleChangeAndNext}
                        handleKeyDown={handleKeyDown}
                        handleBlur={handleBlur}
                    />
                    <MyInput
                        name="num5"
                        errors={errors}
                        values={values?.num5}
                        touched={touched}
                        // handleOnEnter={handleChangeAndNext}
                        handleChange={handleChangeAndNext}
                        handleKeyDown={handleKeyDown}
                        handleBlur={handleBlur}
                    />
                    <MyInput
                        name="num6"
                        errors={errors}
                        values={values?.num6}
                        touched={touched}
                        // handleOnEnter={handleChangeAndNext}
                        handleChange={handleChangeAndNext}
                        handleKeyDown={handleKeyDown}
                        handleBlur={handleBlur}
                    />

                </Box>

                <Typography
                    sx={{
                        fontFamily: 'Causten-Medium',
                        color: '#70798D',
                        fontSize: { xs: '12px' },
                        lineHeight: { xs: '20px' },
                        mb: { xs: '32px' }
                    }}
                >
                    Didn’t get code?&nbsp;&nbsp;
                    <Link onClick={() => setIsSendPopup(true)} style={styles.text_link} >
                        Re-send Code
                    </Link>
                </Typography>

                <BlackButtonOne
                    text="Verifiy"
                    handleClick={handleSubmit}
                    isButtonAktive={(Object.keys(touched).length > 0) && !(Object.keys(errors).length > 0)}
                    styles={{ minWidth: '260px', }}
                />

            </form>

        </Box>
    )
}

const MyInput = ({ handleChange, name, handleBlur, handleKeyDown, values }) => {

    const [focus, setFocus] = useState(false)

    const handleFocuse = (e) => {
        setFocus(true)
    }

    const handleFocuseOut = (e) => {
        setFocus(false)
        handleBlur(e)
    }

    return (
        <Box sx={{ width: '56px', height: "72px", }} >

            <input
                type="number"
                className='input_verify_code'
                value={values}
                min={0}
                max={9}
                onFocus={handleFocuse}
                onBlur={handleFocuseOut}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                name={name}
                style={{
                    border: focus ? '2px solid #65c6ff' : '1px solid #ccc',
                    borderRadius: "8px",
                    outline: 'none',
                    width: '100%',
                    height: "100%",
                    textAlign: "center",
                    fontFamily: "Causten-Regular",
                    fontSize: "40px",
                }}
            />

        </Box>
    )
}

export default VerifyEmailForm