import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Box, IconButton, Link, Typography } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LocationCitySharpIcon from '@mui/icons-material/LocationCitySharp';



const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontFamily: 'Causten-Regular',
        fontSize: '14px',
        lineHeight: '16.8px',
        padding: "8px",
        // margin: '75px',
        position: 'relative',
        left: '20px',
    },
}));



const InternShipMenuItem = ({ application, drawer }) => {

    const { id } = useParams()
    const activeLink = application?.internship?._id === id
    const lacalIsSubOpen = sessionStorage.getItem('interShipMenuItem') ? sessionStorage.getItem('interShipMenuItem') : false
    const [isSubOpen, setIsSubOpen] = useState(lacalIsSubOpen && activeLink)

    // console.log(isSubOpen)

    const handleOpenSubMenu = () => {
        setIsSubOpen(!isSubOpen)
        sessionStorage.setItem("interShipMenuItem", !isSubOpen)
    }


    return (

        <Box>

            {
                drawer ? (
                    <Box>
                        <Box
                            onClickCapture={() => setIsSubOpen(true)}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: !drawer && 'center',
                                backgroundColor: activeLink ? '#FF9737' : 'transparent',
                                borderRadius: '8px',
                            }}
                        >

                            <Link
                                to={`/company/${application?.internship?._id}`}
                                component={RouterLink}
                                sx={{
                                    width: '100%',
                                    p: { xs: '12px' },
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: !drawer && 'center',
                                    textDecoration: 'none',
                                    // backgroundColor:'red',
                                    pr: { xs: '0' },

                                    '&:hover': {
                                        textDecoration: 'none',
                                    },
                                }}
                            >

                                <Box
                                    sx={{
                                        color: activeLink ? '#fff' : '#70798D',
                                        width: { xs: '20px' },
                                        height: { xs: '20px' },
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LocationCitySharpIcon />
                                </Box>

                                <Typography
                                    sx={{
                                        fontFamily: 'Causten-Regular',
                                        fontSize: { xs: '14px' },
                                        lineHeight: { xs: '16.8px' },
                                        color: activeLink ? '#fff' : '#70798D',
                                        pl: { xs: '12px' },
                                        // mr: { xs: '12px' },
                                        flexGrow: 1,
                                        whiteSpace: 'nowrap',
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    {application?.internship?.company?.companyInformation?.companyName}
                                </Typography>

                            </Link>

                            <IconButton
                                onClickCapture={handleOpenSubMenu}
                                sx={{
                                    color: activeLink ? '#fff' : '#70798D',
                                }}
                            >
                                {isSubOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                            </IconButton>

                        </Box>


                        {isSubOpen && (
                            <Box>

                                <SubMenuItem
                                    text="Tasks"
                                    link={`/tasks/${application?.internship?._id}`}
                                    activeLink={activeLink}
                                />

                                <SubMenuItem
                                    text="Messages"
                                    link={`/messages/${application?.internship?._id}`}
                                    activeLink={activeLink}
                                />

                                <SubMenuItem
                                    text="Documents"
                                    link={`/documents/${application?.internship?._id}`}
                                    activeLink={activeLink}
                                />

                            </Box>
                        )}

                    </Box>
                ) : (
                    <LightTooltip title={application?.internship?.company?.companyInformation?.companyName} placement="right" >
                        <Link
                            to={`/company/${application?.internship?._id}`}
                            component={RouterLink}
                            sx={{
                                backgroundColor: activeLink ? '#FF9737' : 'transparent',

                                width: '100%',

                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: "center",


                                p: { xs: '12px' },

                                textDecoration: 'none',
                                borderRadius: '8px',
                            }}
                        >

                            <Box
                                sx={{
                                    width: { xs: '20px' },
                                    height: { xs: '20px' },
                                    color: activeLink ? '#fff' : '#70798D',

                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <LocationCitySharpIcon />
                            </Box>

                        </Link>
                    </LightTooltip>
                )
            }




        </Box>
    )
}



const SubMenuItem = ({ text, link, activeLink }) => {

    const urlPath = useLocation();
    const activeSubLink = [] //urlPath?.pathname?.split('/')
    const isActive = activeSubLink[1] === text?.toLowerCase() && activeLink
    // console.log(isActive)

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                // m:'2px',
            }}
        >
            <Link
                to={link} //{id + "/" + link}
                component={RouterLink}
                sx={{
                    // backgroundColor: isActive ? '#FF9737' : 'transparent',

                    width: '90%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                    color: '#000',
                    p: { xs: '8px' },

                    textDecoration: 'none',
                    borderRadius: '8px',

                    '&:hover': {
                        textDecoration: 'none',
                    }
                }}
            >

                <Typography
                    sx={{
                        fontFamily: 'Causten-Regular',
                        fontSize: { xs: '14px' },
                        lineHeight: { xs: '16.8px' },
                        color: isActive ? '#fff' : '#70798D',
                        // width:'100%',
                        pl: { xs: '12px' },
                        mr: { xs: '12px' },
                        flexGrow: 1,
                        whiteSpace: 'nowrap',
                    }}
                >
                    {text}
                </Typography>

            </Link>
        </Box>
    )
}

export default InternShipMenuItem