import React from 'react'
import { Box, Typography } from '@mui/material'

const UpperTextContainer = ({
  icon,
  title,
  description,

  boxStyle,
  iconStyle,
  titleStyle,
  descriptionStyle,
}) => {

  return (
    <Box
      sx={{
        textAlign: 'center',
        mx: 'auto',
        mb: { xs: '38px', sm: '20px', lg: '80px' },
        maxWidth: { xs: '250px', sm: '320px', lg: '395px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...boxStyle
      }}
    >

      <Box
        sx={{
          width: { xs: '50px', md: '64px' },
          ...iconStyle
        }}
      >
        {icon}
      </Box>

      <Typography
        sx={{
          fontFamily: 'Causten-SemiBold',
          fontSize: { xs: '24px', sm: '32px', lg: '40px' },
          lineHeight: { xs: '28px', sm: '36px', lg: '48px' },
          mt: { xs: '0', sm: '16px', lg: '2vh' },
          mb: { xs: '8px', },
          color: '#0E1436',
          ...titleStyle
        }}
      >
        {title}
      </Typography>

      <Typography
        sx={{
          fontFamily: 'Causten-Medium',
          fontSize: { xs: '16px', sm: '16px', lg: '20px' },
          lineHeight: { xs: '19.2px', sm: '19px', lg: '24px' },
          color: '#96A1BA',
          ...descriptionStyle
        }}
      >
        {description}
      </Typography>
    </Box>
  )
}

export default UpperTextContainer