import React from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid } from '@mui/material'


import {
    TextButtonOne,
    BlackButtonOne,
    BasicInputComponent,
    DatePickerComponent,
    AutoCompleteCityComponent,
    AutoCompleteCountryComponent,
} from 'components';
import { useOnboardingContext } from 'layouts/OnboardingLayout/OnboardingContext/OnboardingContext';
import { OnboardingLayout } from 'layouts/OnboardingLayout';
import { useDispatch, useSelector } from 'react-redux';
import { personalInformationFunc } from 'redux/onboarding/personalInformation';
import { internPersonalInformationFunc } from 'redux/intern/internPersonalInformation';


const ValidationSchema = yup.object({
    fullName: yup.string().required("Required").matches(/^[aA-zZğĞüÜşŞöÖçÇıİ\s]+$/, "Only alphabets are allowed for this field "),
    birthDate: yup.string().required("Required"),
    country: yup.object().nullable()
        .required("Required"),
    city: yup.object().nullable()
        .required("Required"),
})


const PersonalInformation = () => {

    const dispatch = useDispatch()
    const { setCurrentPage, onboardingPageList, setOnboardingFormNumber } = useOnboardingContext()
    const { internPersonalInformationData } = useSelector(state => state?.internPersonalInformation)
    // console.log(internPersonalInformationData)
    const localUser = localStorage.getItem("userAuthInfo") ? JSON.parse(localStorage.getItem("userAuthInfo")) : null


    const {
        values,
        errors,
        touched,
        isValid,
        handleBlur,
        handleChange,
        setFieldValue,
    } = useFormik({
        enableReinitialize: true,
        initialValues: {
            fullName: internPersonalInformationData?.fullName ?? '',
            birthDate: internPersonalInformationData?.birthDate ?? '',
            country: internPersonalInformationData?.country ?? null,
            city: internPersonalInformationData?.city ?? null,
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            //  console.log(values)
        },
    })

    const handleNextStep = async () => {
        try {
            const response = await dispatch(personalInformationFunc({
                fullName: values?.fullName,
                date: values?.birthDate,
                selectedCountry: values?.country?._id,
                selectedCity: values?.city?._id,
                accessToken: localUser?.accessToken
            }))
            // console.log(response)
            if (response?.payload?.data?.onboardPersonalInformationForIntern) {
                setOnboardingFormNumber(prevState => prevState + 1)
                setCurrentPage(onboardingPageList[0]?.pages[2])
                dispatch(internPersonalInformationFunc({ accessToken: localUser?.accessToken }))
            }
        } catch (error) {
            console.log(error)
        }

    }


    // const handleSkipStep = () => {
    //     setOnboardingFormNumber(prevState => prevState + 1)
    //     setCurrentPage(onboardingPageList[0]?.pages[2])
    // }


    const handleBackStep = () => {
        setOnboardingFormNumber(prevState => prevState - 1)
        setCurrentPage(onboardingPageList[0]?.pages[0])
    }


    return (
        <OnboardingLayout>

            <Grid container spacing={3} >

                <Grid item xs={12} lg={6}>
                    <BasicInputComponent
                        name="fullName"
                        label="Full Name"
                        placeholder="Please write your full name"
                        error={touched?.fullName && errors?.fullName}
                        value={values?.fullName}

                        handleBlur={handleBlur}
                        handleChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <DatePickerComponent
                        name="birthDate"
                        label="Birthday"
                        placeholder="Select your birthday"
                        errors={errors?.birthDate}
                        value={values?.birthDate}
                        touched={touched?.birthDate}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                        minDate="1980-1-1"
                        maxDate="2004-12-31"
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <AutoCompleteCountryComponent
                        error={touched?.country && errors?.country}
                        values={values?.country}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <AutoCompleteCityComponent
                        error={touched?.city && errors?.city}
                        values={values?.city}
                        countryId={values?.country?._id}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

            </Grid>

            <Box
                sx={{
                    display: 'flex',
                    gap: { xs: 3 },
                    justifyContent: 'flex-end',
                    mt: '20px',
                }}
            >
                {/* <TextButtonOne
                    text="back"
                    onClick={handleBackStep}
                /> */}

                <BlackButtonOne
                    text="Next Step"
                    handleClick={handleNextStep}
                    styles={{ maxWidth: '160px' }}
                    // isButtonAktive={isButtonAktive}
                    isButtonAktive={isValid}
                />
            </Box>

        </OnboardingLayout>
    )
}

export default PersonalInformation