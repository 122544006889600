import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    emailConfirmationData: [],
    emailConfirmationLoading: false,
    emailConfirmationError: null,
};


export const emailConfirmationFunc = createAsyncThunk("emailConfirmation/emailConfirmationFunc", async ({code, email}) => {

    var config = {
        method: 'get',
        url: `https://api.internif.com/auth/auth/verifyEmail?code=${code}&email=${email}`,
        headers: {}
    };

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const emailConfirmation = createSlice({
    name: "emailConfirmation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(emailConfirmationFunc.pending, (state) => {
            state.emailConfirmationLoading = true;
        });
        builder.addCase(emailConfirmationFunc.fulfilled, (state, action) => {
            state.emailConfirmationLoading = false;
            state.emailConfirmationData = action.payload;
        });
        builder.addCase(emailConfirmationFunc.rejected, (state, action) => {
            state.emailConfirmationLoading = false;
            state.emailConfirmationError = action.error.message;
        });
    }
});

export default emailConfirmation.reducer;