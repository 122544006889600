import React, {
    useState
} from 'react'
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid, Typography } from '@mui/material';
import ArchitectureIcon from '@mui/icons-material/Architecture';


import { BasicInputComponent } from 'components';
import { DatePickerComponent } from 'components';
import { BlackButtonOne } from 'components';



const ValidationSchema = yup.object().shape({
    name: yup.string()
        .required("Required!"),
    organization: yup.string()
        .required("Required!"),
    issueDate: yup.string()
        .required("Required!"),
    expirationDate: yup.string()
        .required("Required!"),
    stillAttending: yup.boolean()
        .required("Required!"),
})


const CertificationFormSection = ({ selectedItem, handleAddOrUpdate }) => {

    const {
        errors,
        values,
        touched,
        isValid,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        enableReinitialize: true,
        validationSchema: ValidationSchema,
        initialValues: {
            name: selectedItem?.name ?? '',
            organization: selectedItem?.organization ?? '',
            issueDate: selectedItem?.issueDate ?? '',
            expirationDate: selectedItem?.expirationDate ?? '',
            stillAttending: selectedItem?.stillAttending ?? false,
        },
        onSubmit: (values, { resetForm }) => {

            handleAddOrUpdate({ values })
            resetForm({
                name: '',
                organization: '',
                issueDate: '',
                expirationDate: '',
                stillAttending: false,
            })

        },

    })

    // useEffect(() => {
    //     setFieldValue("name", selectedItem?.name ?? '')
    //     setFieldValue("organization", selectedItem?.organization ?? '')
    //     setFieldValue("issueDate", selectedItem?.issueDate ?? '')
    //     setFieldValue("expirationDate", selectedItem?.expirationDate ? selectedItem?.expirationDate : '')
    //     setFieldValue("stillAttending", selectedItem?.expirationDate ? false : true)
    // }, [selectedItem]);

    // console.log(isValid)


    return (
        <Grid container spacing={3}>

            <Grid item xs={12} sm={6}>
                <BasicInputComponent
                    name="name"
                    label="Certificate Name"
                    placeholder="Enter certificate name"
                    error={touched?.name && errors?.name}
                    value={values?.name}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <BasicInputComponent
                    name="organization"
                    label="Organization"
                    placeholder="Enter organization name"
                    value={values?.organization}
                    error={touched?.organization && errors?.organization}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <DatePickerComponent
                    name="issueDate"
                    label="Issue Date"
                    placeholder="Enter date"
                    value={values?.issueDate}
                    error={touched?.issueDate && errors?.issueDate}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>


            <Grid item xs={12} sm={6}>
                <DatePickerComponent
                    name="expirationDate"
                    label="Expiration Date"
                    placeholder="Enter date"
                    value={values?.expirationDate}
                    error={touched?.expirationDate && errors?.expirationDate}

                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                />
            </Grid>


            <Grid item xs={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                }}
            >
                <BlackButtonOne
                    text={selectedItem ? "Update" : "Add"}
                    handleClick={handleSubmit}
                    isButtonAktive={isValid}
                    disabled={false}
                    styles={{
                        width: '150px',
                    }}
                />

            </Grid>

            <Grid item xs={12}>
                <CertificationList />
            </Grid>

        </Grid>
    )
}


const CertificationList = ({ newList, setSelectedItem, setDelPopup }) => {

    return (
        <Box>

            <Typography
                sx={{
                    fontFamily: 'Causten-Medium',
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: '#0E1436',
                    mb: { xs: '24px' },
                }}
            >
                Your Certificate(s)
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'row', md: 'row' },
                    gap: { xs: '16px' },
                    flexWrap: 'wrap',
                    justifyContent: { xs: 'center', sm: 'left' },
                }}
            >
                {
                    newList?.map((item, index) => {
                        return (
                            <ListItem
                                key={index}
                                item={item}
                                setSelectedItem={setSelectedItem}
                                setDelPopup={setDelPopup}
                            />
                        )
                    })
                }
            </Box>
        </Box>
    )
}

const ListItem = ({ item, handleDelete, setDelPopup, setSelectedItem }) => {

    const [isMenuOpen, setIsMenuOpen] = useState(null);

    const handleClick = (event) => {
        setIsMenuOpen(event.currentTarget);
    };


    return (
        <Box
            sx={{
                backgroundColor: '#fff',
                border: '1px solid #E3E7F2',
                borderRadius: '8px',
                px: { xs: '16px' },
                pb: { xs: '25px' },
                pt: { xs: '25px' },

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer',

                position: 'relative',

                '&:hover': {
                    backgroundColor: 'rgba(255, 152, 55, 0.1)',
                    border: '1px solid #FF9737',
                }
            }}
        >


            <Box
                sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '0px',
                }}
            >
                {/* <PopoverMenu
                    open={isMenuOpen}
                    handleClose={() => setIsMenuOpen(false)}
                    handleClick={handleClick}
                    handleDelete={() => setDelPopup(item?._id )}
                    handleUpdate={() => setSelectedItem(item)}
                /> */}
            </Box>

            <Box
                sx={{
                    width: { xs: '36px', md: '36px' },
                    height: { xs: '36px', md: '36px' },
                    boxShadow: '0px 0px 10px 0px #ccc',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ArchitectureIcon
                    sx={{
                        color: '#aaa',
                        backgroundColor: '#000',
                        borderRadius: '50%',
                        padding: '3px',
                    }}
                />
            </Box>

            <Typography
                sx={{
                    fontFamily: 'Causten-SemiBold',
                    fontSize: '14px',
                    lineHeight: '16px',
                    color: '#0E1436',
                    mb: { xs: '8px' },
                }}
            >
                {item.name}
            </Typography>


            <Typography
                sx={{
                    fontFamily: 'Causten-Regular',
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: '#0E1436',
                    mb: { xs: '22px' },
                }}
            >
                {item.organization}
            </Typography>


            <Typography
                sx={{
                    fontFamily: 'Causten-Regular',
                    fontSize: '12px',
                    lineHeight: '14px',
                    color: '#70798D',
                    // mb: { xs: '16px' },
                }}
            >
                {moment(item.issueDate).format('MMM YYYY')}
                -
                {" " + (moment.utc(item?.expirationDate).format('MMM YYYY') === "Invalid date" ? "Continue" : moment.utc(item?.expirationDate).format('MMM YYYY'))}
            </Typography>

        </Box>
    )
}

export default CertificationFormSection