import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    onboardUpdateUsageReasonData: null,
    onboardUpdateUsageReasonLoading: false,
    onboardUpdateUsageReasonError: null,
};




export const onboardUpdateUsageReasonFunc = createAsyncThunk("onboardUpdateUsageReason/onboardUpdateUsageReasonFunc", async (values) => {

    console.log(values);
    var data1 = JSON.stringify({
        query: `mutation UpdateUsageReasons($updateUsageReasonsInput: UpdateUsageReasonsInput!) {
            updateUsageReasons(updateUsageReasonsInput: $updateUsageReasonsInput) {
              _id
            }
          }`,
        variables: {
            "updateUsageReasonsInput": {
                "reasons": values.itemsForDispatch
            }
        }
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        console.log(err.response.data);
        return err;
    });

    const data = await response;
    return data;
});


const onboardUpdateUsageReason = createSlice({
    name: "onboardUpdateUsageReason",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(onboardUpdateUsageReasonFunc.pending, (state) => {
            state.onboardUpdateUsageReasonLoading = true;
        });
        builder.addCase(onboardUpdateUsageReasonFunc.fulfilled, (state, action) => {
            console.log(action.payload)
            state.onboardUpdateUsageReasonLoading = false;
            state.onboardUpdateUsageReasonData = action.payload.data;
            state.onboardUpdateUsageReasonError = action.payload.errors;
        });
        builder.addCase(onboardUpdateUsageReasonFunc.rejected, (state, action) => {
            console.log(action.error)
            state.onboardUpdateUsageReasonLoading = false;
            state.onboardUpdateUsageReasonError = action.error.message;
        });
    }
});


export default onboardUpdateUsageReason.reducer;