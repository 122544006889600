import React from 'react'
import { Box, Grid } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup';

// import InputDepartment from './InputDepartment'
// import InputEndDay from './InputEndDay'
// import InputStartDate from './InputStartDate'
// import InputUniversity from './InputUniversity'

import {
    BlackButtonOne,
    WhiteButtonOne,
    DatePickerComponent,
    SwipeableAutoCompleteComponent,
    UniversityAutocompleteComponent,
} from 'components';
import { TextButtonOne } from 'components';
import { useSelector } from 'react-redux';



const ValidationSchema = yup.object().shape({
    university: yup.object().nullable()
        .required('Required!'),
    department: yup.object().nullable()
        .required('Required!'),
    startDate: yup.date("You must select a date")
        .required('Required!'),
    finishDate: yup.date("You must select a date")
        .required('Required!'),
    // stillAttending: yup.boolean()
})

const UniversityFormSection = ({ addOrUpdateUniversity, setShowForm, selectedItem }) => {

    const { universityDepartmentsData } = useSelector(state => state?.universityDepartments)

    // ************************************* formik  start ******************
    const {
        errors,
        values,
        touched,
        isValid,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
    } = useFormik({
        enableReinitialize: true,
        initialValues: {
            university: selectedItem?.university ?? null,
            department: selectedItem?.department ?? null,
            startDate: selectedItem?.startDate ?? '',
            finishDate: selectedItem?.finishDate ?? "",
            // stillAttending: selectedItem?.finishDate ? false : true,
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            addOrUpdateUniversity({ values })
        }

    })


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%'
            }}>

            <Grid container spacing={3}>

                <Grid item xs={12} md={6} >
                    <UniversityAutocompleteComponent
                        name="university"
                        label="University"
                        placeholder="Select your university"
                        error={touched?.university && errors?.university}
                        values={values?.university}
                        // list={[{ _id: "1", name: "university" }]}

                        // handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <SwipeableAutoCompleteComponent
                        name="department"
                        label="Department"
                        placeholder="Enter your department"
                        error={touched?.department && errors?.department}
                        values={values?.department}
                        list={universityDepartmentsData ?? [{ _id: "1", name: "department" }]}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <DatePickerComponent
                        name="startDate"
                        label="Start Date"
                        placeholder="Select data"
                        error={touched?.startDate && errors?.startDate}
                        value={values?.startDate}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}

                        minDate="1990-1-1"
                        maxDate={new Date().getTime()}
                    />
                </Grid>

                <Grid item xs={12} md={6} >
                    <DatePickerComponent
                        name="finishDate"
                        label="Start Date"
                        placeholder="Select data"
                        error={touched?.finishDate && errors?.finishDate}
                        value={values?.finishDate}

                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}

                        minDate={values?.startDate ? values.startDate : "1990-1-1"}
                        maxDate="2040-1-1"
                    />
                </Grid>



            </Grid>

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: { xs: '10px' },
                }}
            >

                <TextButtonOne
                    text="Cancel"
                    onClick={() => setShowForm(false)}
                    isButtonAktive={true}
                />

                <BlackButtonOne
                    text={`${selectedItem ? "Update" : "Add"} University`}
                    handleClick={handleSubmit}
                    isButtonAktive={isValid}
                    styles={{ maxWidth: '160px' }}
                />

            </Box>


        </Box>
    )
}

export default UniversityFormSection