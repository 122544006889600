import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    onboardEducationData: null,
    onboardEducationLoading: false,
    onboardEducationError: null,
    highSchoolData: null,
    universityData: null,
};



export const onboardEducationFunc = createAsyncThunk("onboardEducation/onboardEducationFunc", async (values) => {

    console.log('valuuuuu ', values);
    var data1 = JSON.stringify({
        query: `mutation onboardEducation($educationInput: EducationInput!) {
            onboardEducation(educationInput: $educationInput) {
              success
            }
          }`,
        variables: values.educationsFinal

    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        console.log(err.response.data);
        return err;
    });

    const data = await response;
    return data;
});


const onboardEducation = createSlice({
    name: "onboardEducation",
    initialState,
    reducers: {
        setHighSchoolData: (state, action) => {
            state.highSchoolData = action.payload;
        },
        setUniversityData: (state, action) => {
            state.universityData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(onboardEducationFunc.pending, (state) => {
            state.onboardEducationLoading = true;
        });
        builder.addCase(onboardEducationFunc.fulfilled, (state, action) => {
            state.onboardEducationLoading = false;
            state.onboardEducationData = action.payload.data?.onboardEducation;
            state.onboardEducationError = action.payload.errors;
        });
        builder.addCase(onboardEducationFunc.rejected, (state, action) => {
            state.onboardEducationLoading = false;
            state.onboardEducationError = action.error.message;
        });
    }
});

export const {
    setHighSchoolData,
    setUniversityData
} = onboardEducation.actions;


export default onboardEducation.reducer;