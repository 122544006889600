import * as yup from 'yup';
import { useFormik } from 'formik'
import { Box, Grid, Typography } from '@mui/material'

// import InputEmail from './InputEmail'
// import InputName from './InputName'
// import InputCity from './InputCity'
// import InputCountry from './InputCountry'
// import InputBirthday from './InputBirthday'
// import InputPhoneNumber from './InputPhoneNumber'

// import HandleNextStep from '../../../../OnBoarding/Components/HandleNextStep'
// import PersonalIcon from '../../../../../Assets/Icons/onboarding/upper-text-personel-icon.png'
import {
  BlackButtonOne,
  BasicInputComponent,
  DatePickerComponent,
  AutoCompleteCityComponent,
  AutoCompleteCountryComponent,
} from 'components'


const ValidationSchema = yup.object({
  fullName: yup.string().required("Required"),
  birthDate: yup.string().required("Required"),
  phoneNumber: yup.string().min(8, "Phone number is not valid"),
  // phoneNumber: yup.string().min(18, "Phone number is not valid"),
  email: yup.string().email().required("Required"),
  country: yup.object().nullable().required("Required"),
  city: yup.object().nullable().required("Required"),
})

const PersonalInfoForm = () => {


  const {
    values,
    errors,
    touched,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      fullName: "",
      birthDate: "",
      phoneNumber: "",
      email: "",
      country: null,
      city: null,
    },
    enableReinitialize: true,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      console.log(values)
    }
  })

  // console.log(isValid, touched, errors, values)

  return (
    <Box>

      <Box
        sx={{
          mb: { xs: '24px ' },
          display: 'flex',
          alignItems: 'center',
          gap: { xs: '16px', sm: '16px' }
        }}
      >

        <Box
          sx={{
            // display: { xs: 'none', sm: 'block' } ,
            width: { xs: '50px', sm: '64px' },
            height: { xs: '50px', sm: '64px' },
          }}
        >
          {/* <img src={PersonalIcon} alt="" width="64px" height="64px" /> */}
        </Box>



        <Box>

          <Typography
            sx={{
              fontFamily: 'Causten-SemiBold',
              fontSize: { xs: '20px', sm: '22px', lg: '24px' },
              lineHeight: { xs: '16px', sm: '20px', lg: '28px' },
              color: '#0E1436',
              // whiteSpace: 'nowrap',
              mb: '4px',
            }}
          >
            Personal Information
          </Typography>

          <Typography
            sx={{
              fontFamily: 'Causten-Regular',
              fontSize: { xs: '12px', sm: '14px', lg: '16px' },
              lineHeight: { xs: '12px', sm: '14px', lg: '16px' },
              color: '#96A1BA',
              // whiteSpace: 'nowrap',
            }}
          >
            Sagittis non ac, tellus, id praesent elit adipiscing.
          </Typography>

        </Box>

      </Box>

      <Grid container spacing={3}>

        <Grid item xs={12} lg={6}>
          <BasicInputComponent
            name="fullName"
            label="Full Name"
            placeholder="Enter Full Name"
            error={touched.fullName && errors.fullName}
            value={values.fullName}

            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <DatePickerComponent
            name="birthDate"
            label="Birth Date"
            placeholder="Select date"
            error={touched.birthDate && errors.birthDate}
            value={values.birthDate}

            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <BasicInputComponent
            name="email"
            label="Email"
            placeholder="Enter Full Name"
            error={touched.email && errors.email}
            value={values.email}

            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <BasicInputComponent
            name="phoneNumber"
            label="Phone Number"
            placeholder="Enter phone number"
            error={touched.phoneNumber && errors.phoneNumber}
            value={values.phoneNumber}

            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <AutoCompleteCountryComponent
            error={touched.country && errors.country}
            values={values.country}

            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <AutoCompleteCityComponent
            countryId={values.country?._id}
            country={values?.country}
            error={touched.city && errors.city}
            values={values.city}

            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </Grid>

        <Grid item xs={12}>
          <BlackButtonOne
            text="Update"
            isButtonAktive={isValid}
            handleClick={handleSubmit}
            styles={{
              width: { xs: '100%', sm: 'auto' },
              mt: { xs: '20px', md: '20px' }
            }}
          />
        </Grid>

      </Grid>

    </Box>
  )
}

export default PersonalInfoForm