import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import initialConfig from "./initialConfig";


const initialState = {
    languagesData: null,
    languagesLoading: false,
    languagesError: null,
};


export const languagesFunc = createAsyncThunk("languages/languagesFunc", async (values) => {

    var data1 = JSON.stringify({
        query: `query Data {
            parameters {
              languages {
                data {
                  _id
                  name
                }
              }
            }
          }`
    });

    var config = initialConfig('https://api.internif.com/user/graphql', data1, values.accessToken);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const languages = createSlice({
    name: "languages",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(languagesFunc.pending, (state) => {
            state.languagesLoading = true;
        });
        builder.addCase(languagesFunc.fulfilled, (state, action) => {
            console.log(action?.payload)
            state.languagesLoading = false;
            state.languagesData = action.payload.data?.parameters.languages?.data;
        });
        builder.addCase(languagesFunc.rejected, (state, action) => {
            console.log(action.error)
            state.languagesLoading = false;
            state.languagesError = action.error.message;
        });
    }
});

export default languages.reducer;