import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import initialConfig from "./initialConfig";

const initialState = {
    signinData: [],
    signinLoading: false,
    signinError: null,
    accessToken: null,
    refreshToken: null,
    email: null,
    password: null,
    role: null,
    myId: null,
};


export const signinFunc = createAsyncThunk("signin/signinFunc", async (values) => {
    var data1 = JSON.stringify({
        "email": values.email,
        "password": values.password
    });

    var config = initialConfig('https://api.internif.com/auth/auth/login', data1);

    const response = axios(config).then((res) => {
        const data = res.data;
        return data;
    }).catch((err) => {
        return err;
    });

    const data = await response;
    return data;
});


const signin = createSlice({
    name: "signin",
    initialState,
    reducers: {
        setAccessToken: (state, action) => {
            state.accessToken = action.payload;
            // action.payload && AsyncStorage.setItem("@ACCESSTOKEN", action.payload);
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload;
            // action.payload && AsyncStorage.setItem("@REFRESHTOKEN", action.payload);
        },
        setPassword: (state, action) => {
            state.password = action.payload;
            // action.payload && AsyncStorage.setItem("@PASSWORD", action.payload);
        },
        setEmail: (state, action) => {
            state.email = action.payload;
            // action.payload && AsyncStorage.setItem("@EMAIL", action.payload);
        },
        setRole: (state, action) => {
            state.role = action.payload;
            // action.payload && AsyncStorage.setItem("@USERROLE", action.payload);
        },
        setMyId: (state, action) => {
            state.myId = action.payload;
            // action.payload && AsyncStorage.setItem("@MYID", action.payload);
        },
        removeUser: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.email = null;
            state.password = null;
            state.role = null;
            state.myId = null;
            // AsyncStorage.removeItem("@ACCESSTOKEN");
            // AsyncStorage.removeItem("@REFRESHTOKEN");
            // AsyncStorage.removeItem("@EMAIL");
            // AsyncStorage.removeItem("@PASSWORD");
            // AsyncStorage.removeItem("@USERROLE");
            // AsyncStorage.removeItem("@MYID");
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signinFunc.pending, (state) => {
            state.signinLoading = true;
        });
        builder.addCase(signinFunc.fulfilled, (state, action) => {
            state.signinLoading = false;
            console.log(action.payload)

            state.signinData = action.payload;
            if (action.payload.accessToken) {
                localStorage.setItem("userAuthInfo", JSON.stringify(action.payload))
                state.accessToken = action.payload.accessToken;
                state.refreshToken = action.payload.refreshToken;
                state.email = action.payload.user.email;
                state.role = action.payload.user.role;
                state.myId = action.payload.user.userId;
                // AsyncStorage.setItem("@ACCESSTOKEN", action.payload.accessToken);
                // AsyncStorage.setItem("@REFRESHTOKEN", action.payload.refreshToken);
                // AsyncStorage.setItem("@EMAIL", action.payload.user.email);
                // AsyncStorage.setItem("@USERROLE", action.payload.user.role);
                // AsyncStorage.setItem("@MYID", action.payload.user.userId);
            }
        });
        builder.addCase(signinFunc.rejected, (state, action) => {
            state.signinLoading = false;
            state.signinError = action.error.message;
        });
    }
});

export const { setEmail, setPassword, setRole, setAccessToken, setRefreshToken, setMyId, removeUser } = signin.actions;

export default signin.reducer;