import React from 'react'
import Lottie from 'react-lottie'
import { Backdrop, Box } from '@mui/material'

import {
    ErrorAnimatedImage,
    LoadingAnimatedImage,
    SuccessfullAnimatedImage,
} from 'assets'


const LottieLoadingAndSuccessfull = ({ error = false, successfull = false, loading = false, onClose = () => null }) => {


    const defaultOptions = {
        loop: loading ? true : false,
        autoplay: true,
        animationData: loading ? LoadingAnimatedImage : (error ? ErrorAnimatedImage : SuccessfullAnimatedImage),
        // rendererSettings: {
        //   preserveAspectRatio: "xMidYMid slice"
        // }
    };


    return (
        <Backdrop
            onClick={onClose}
            open={successfull || loading || error}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1000,
                backgroundColor: !loading ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box sx={{ p: { xs: '10px', sm: '50px', }, borderRadius: '8px', }} >

                <Lottie
                    options={defaultOptions}
                    height={loading ? 300 : 100}
                    width={loading ? 300 : 100}
                />

            </Box>
        </Backdrop>
    )
}

export default LottieLoadingAndSuccessfull