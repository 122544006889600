import React from 'react'
import { Link, Typography } from '@mui/material'
import StraightIcon from '@mui/icons-material/Straight';


const GoToHome = () => {

    const handleClick = () => {
        window.location.replace('https://www.internif.com')
    }

    return (
        <Link
            onClick={handleClick}
            sx={{
                position: 'absolute',
                top: { md: '0px', lg: '48px' },
                left: { md: '0px', lg: '48px' },
                alignItems: 'center',
                display: { xs: 'none', md: 'flex' },
                gap: 1,
                textDecoration: 'none',
                cursor: 'pointer',
            }}
        >
            {/* employif te  */}
            {/* <StraightIcon sx={{ transform: 'rotate(-90deg)', color: '#96A1BA' }} /> */}
            <Typography
                sx={{
                    color: '#96A1BA',
                    fontFamily: 'Causten-Medium',
                    fontSize: '14px',
                }}
            >
                {/* Back to Home */}
                Go to InternIF
            </Typography>
        </Link>
    )
}

export default GoToHome