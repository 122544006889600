import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Checkbox, Grid, Typography } from '@mui/material';

import { BlackButtonOne, BasicInputComponent } from 'components';


const ValidationSchema = Yup.object({
    email: Yup.string()
        .email('Please enter a valid email address!')
        .required('Required!'),
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required('Required!'),
})


const SignInForm = ({ handleSignin, forgetPasswordLink }) => {

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        values,
        touched
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
            acceptTerms: false,
        },
        validationSchema: ValidationSchema,
        onSubmit: () => {
            handleSignin({ values })
        }
    })


    return (
        <form onSubmit={handleSubmit} >

            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    gap: '24px',
                }}
            >

                <BasicInputComponent
                    name="email"
                    type="email"
                    label="E-mail Address"
                    placeholder="Enter your e-mail address"

                    value={values?.email}
                    error={touched.email && errors.email}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                />

                <BasicInputComponent
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="Enter a strong password"

                    rightText="Forgot Password"
                    path={forgetPasswordLink}

                    error={touched.password && errors.password}
                    value={values?.password}
                    handleBlur={handleBlur}
                    handleChange={handleChange}

                    showPasswordIcon
                />


                <Box sx={{ width: '100%', textAlign: 'left' }}>

                    <Grid container spacing={1}>

                        <Grid item xs={1}
                            sx={{
                                display: 'flex',
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                            }}
                        >
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'Checkbox demo',
                                }}
                                name="acceptTerms"
                                id="acceptTerms"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                sx={{
                                    p: 0,
                                    color: "#70798D",
                                    '&.Mui-checked': {
                                        color: "#191922",
                                    },
                                }}
                            />
                        </Grid>

                        <Grid
                            item xs={11}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography sx={{
                                fontFamily: 'Causten-Medium',
                                color: touched.acceptTerms && errors.acceptTerms ? 'red' : (values?.acceptTerms ? '#000' : '#70798D'),
                                fontSize: { xs: '12px' },
                                lineHeight: { xs: '20px' },
                            }}>
                                Remember me
                            </Typography>
                        </Grid>

                    </Grid>

                    <Typography sx={{ color: 'red' }}>{touched.acceptTerms && errors.acceptTerms}</Typography>

                </Box>

                <BlackButtonOne
                    text="Sign In"
                    handleClick={handleSubmit}
                    isButtonAktive={!errors?.email && !errors?.password}
                />

            </Box>

        </form>
    );
};

export default SignInForm;