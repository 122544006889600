import {
  AppBar,
  Toolbar,
  Link,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import StraightIcon from '@mui/icons-material/Straight';

import { InternifLogoOnly } from 'assets';
import AuthSideNavbar from './_AuthSideNavbar';

const appType = process.env.REACT_APP_TARGET_APP

const AuthNavbar = () => {

  const isMobile = useMediaQuery('(max-width:900px)')

  return (
    <AppBar
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: "blur(8px)",

        maxWidth: '1280px',
        height: { md: '140px' },
        justifyContent: 'center',
        boxShadow: isMobile ? '0px 0px 10px 0px #ccc' : 0,
        p: { m: '0px!important' },
        px: { xs: 2, md: 0 },
        m: 'auto',
        display: { xs: 'block', md: 'none' },
        top: 0,

      }}
    >
      <Toolbar sx={{
        p: '0px!important',
        maxWidth: '1280px',
        display: 'flex',
        justifyContent: 'space-between',
      }} >

        {appType !== 'employif' && (<IconButton onClick={() => window.location.href = "https://www.internif.com"} >
          <StraightIcon sx={{
            width: '40px',
            height: '40px',
            transform: 'rotate(270deg)',
            // color: '#96A1BA',
          }} />
        </IconButton>)}

        <Link component={RouterLink} to="" sx={{ width: '50px', height: '50px', m: 'auto' }} >
          <InternifLogoOnly styles={{ width: "36px" }} />
        </Link>

        {appType !== 'employif' && <AuthSideNavbar />}

      </Toolbar>
    </AppBar>
  )
};

export default AuthNavbar;